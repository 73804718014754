import React, { useState } from 'react'
import MyModal from '../../components/Modal/MyModal'
import { useNavigate } from 'react-router-dom';
import { useContactsContext } from '../../hooks/ContantsContext';
import { useRegionsContext } from '../../hooks/RegionsContext';
import axiosConfig from '../../utils/axiosConfig';
import LoadingSpinner from '../../components/Loanding/LoadingSpinner';
import Button from '../../components/Buttons/Button';
import colors from '../../components/Colors/color';
import "./AddedContactStyle.css"
import "../Invitations/InvitationsStyles.css"
import CustomAlert from '../../components/Alert/CustomAlert';

function AddNewContact({onClose}) {
const {refreshAddedContacts} = useContactsContext();
const{areas}=useRegionsContext();
// console.log("areas", areas[0].area.id);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [father_name, setFather_name] = useState("")
  const [phone, setPhone] = useState("")
  const [areas_id, setAreasId] = useState(areas.length === 1 ? areas[0].area.id : ' ')
  // const [areas_id, setAreasId] = useState("5")
  const [areaName, setAreaName] = useState("")

  const handleErrorClose = () => {
    setError(null);
  };

  const createNewContact = async (e) => {
    e.preventDefault();
    if (!firstname || !lastname || !father_name || !phone || !areas_id) {
        setError('لم يتم إدخال جميع الحقول');
        return;
      }
    setLoading(true); // Set loading state to true when form is submitted
    const contactFormData = new FormData();
    contactFormData.append('firstname', firstname);
    contactFormData.append('lastname', lastname);
    contactFormData.append('father_name', father_name);
    contactFormData.append('phone', phone);
    contactFormData.append('areas_id', areas_id);
    if(areas_id === "1"){
      contactFormData.append('areas_notes', areaName);
    }
    try {
        const { data } = await axiosConfig.post(`/contact/add`, contactFormData);
        console.log(data.message);
        refreshAddedContacts()
        onClose()
    } catch (error) {
        if (error.response.status === 422) {
            console.log(error.response.data.errors);
            setError("رجاء أدخل الحقول بشكل الصحيح");
        } else {
            console.log(error.response.data.message);
            // setError(error.response.data.message);
            setError("رجاء أدخل الحقول بشكل الصحيح");
        }
    } finally {
        setLoading(false); // Set loading state back to false after request completes
    }
  };

  return (
    <div >
      <MyModal
        // title="Add Contact"
        show={true} // Always keep the modal open
        onHide={onClose}
        // modal_classname="add_Contact"
        body={
          <div>
             {loading ? (
              <LoadingSpinner/> // Render loading spinner if data is loading
            ) : (
              <>
              <form
               className="form-grid-modal">
               <div className='add_title'>إضافة اسم جديد</div>
               <div className='column'>
                  <div className="form-group-modal">
                    <input type="text" required className="form-control-modal" name="firstname"
                      placeholder="الاسم"
                      value={firstname} onChange={(e) => { setFirstname(e.target.value) }}
                    />
                  </div>
                  <div className="form-group-modal">
                    <input type="text" required className="form-control-modal" name="lastname"
                      placeholder="العائلة"
                      value={lastname} onChange={(e) => { setLastname(e.target.value) }}
                    />
                  </div>
                  <div className="form-group-modal">
                  <div className="small-select-container">
{/* <label>المدينة التي تتوافر فيها هذه الفئة</label> */}
<div 
                            // className="select-container"
                            >
                            <select 
                            value={areas_id}
                            className="form-control-modal"
                            onChange={(e)=>{setAreasId(e.target.value)}}
                            >
                                <option  
                                // class="select-options"
                                 value={" "} disabled selected>العنوان..</option>
                                {areas.map((area ,key) =>(
                            <option value={area.area.id} key={key}>
                                {area.area.ar_name}
                            </option>
                        ))};
                            </select>
                            </div>
                  </div>
                            
                        </div>
                </div>
                <div className='column'>
                <div className="form-group-modal">
                    <input type="text" required className="form-control-modal" name="father_name"
                      placeholder="اسم الأب"
                      value={father_name} onChange={(e) => { setFather_name(e.target.value) }}
                    />
                  </div>
                  <div className="form-group-modal">
                    <input type="text" required className="form-control-modal" name="background_color"
                      placeholder="رقم الهاتف"
                      value={phone} onChange={(e) => { setPhone(e.target.value) }}
                    />
                  </div>
                  {areas_id === "1" &&
                   <div className="form-group-modal">
                   <input type="text" required className="form-control-modal" name="areaName"
                     placeholder="اسم المدينة "
                     value={areaName} onChange={(e) => { setAreaName(e.target.value) }}
                   />
                 </div>
                  }
                   {/* {error && (
                    <div className="error-container">
                      <div className="error-message">{error}</div>
                    </div>
                  )} */}
                  <div className='save-btn-modal'>
                    <Button
                      className="add_btn"
                      b_color={colors.light_gold}
                      t_color={colors.white}
                    onClick={createNewContact}
                    >
                      حفظ
                    </Button>
                  </div>
                </div>
                </form>
                {error !== null && <CustomAlert message={error}  onClose={handleErrorClose} />}  
                </>
            )
            }
            </div>
        }
            />
    </div>
  )
}

export default AddNewContact
