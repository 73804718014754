import './App.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AuthLayout from './layouts/AuthLayout';
import LoginForm from './auth/Login/LoginForm';
import SignupForm from './auth/Signup/SignupForm';
import HomeLayout from './layouts/HomeLayout';
import ContactPage from './pages/Contact/ContactPage';
import UsersPage from './pages/Users/UsersPage';
import AreasPage from './pages/Areas/AreasPage';
import { useEffect } from 'react';
import { RegionsProvider } from './hooks/RegionsContext';
import { UserProvider } from './hooks/UsersContext';
import { ContactsProvider } from './hooks/ContantsContext';
import PlanPage from './pages/Plans/PlanPage';
import { PlanProvider } from './hooks/PlansContext';
import InvitationsPage from './pages/Invitations/InvitationsPage';
import ProfilePage from './pages/Profile/ProfilePage';
import SentInvitationsPage from './pages/Invitations/MySentInvitationsPage';
import AddedContactsPage from './pages/AddedContacts/AddedContactsPage';
import Cookies from "js-cookie"
import GoogleRedirect from './auth/GoogleRedirect';
import PrivacyAndPolicies from './pages/Profile/PrivacyAndPolicies';
import TermsOfTheServices from './pages/Profile/TermsOfTheServices';



function App() {

  // useEffect(() => {
  //   const token = Cookies.get('access_token');
  //   console.log("token Use Effect ", token);
  // }, []);
const navigate =useNavigate();
  // const token = localStorage.getItem('access_token');
  const token = Cookies.get('access_token');
  // console.log("token ",token);
  const location = useLocation();

  useEffect(() => {
    const protectedRoutes = ['/contacts', '/users', '/areas','/profile'];
  
    if (!token) {
      // If there's no token, redirect to login for protected routes
      if (protectedRoutes.some(route => location.pathname.startsWith(route))) {
        navigate('/login')
        // window.location.pathname = '/login';
      }
      // Redirect to login for the home route if there's no token
      if (location.pathname === '/') {
        navigate('/login')
        // window.location.pathname = '/login';
      }
    }if(token){
      if (location.pathname === '/login' || location.pathname === '/register' ) {
        navigate('/profile')
        // window.location.pathname = '/login';
      }
    }
  }, [token, location]);


  return (
    <RegionsProvider>
      <ContactsProvider>
        <PlanProvider>
      <UserProvider>  
    <Routes>
      <Route path="*" element={<div>Page not Found</div>} />
      <Route path="/login" element={<AuthLayout><LoginForm /></AuthLayout>} />
      <Route path="/register" element={<AuthLayout><SignupForm /></AuthLayout>} />
      {/* <Route path="/redirect_google" element={<AuthLayout><GoogleRedirect /></AuthLayout>} /> */}
      <Route path="/" element={<HomeLayout />} />
      <Route path="/contacts" element={<ContactPage />} />
      {/* <Route path="/users" element={<UsersPage />} /> */}
      <Route path="/areas" element={<AreasPage />} />
      {/* <Route path="/plans" element={<PlanPage />} /> */}
      <Route path="/invitations" element={<InvitationsPage />} />
      <Route path="/sent_invitations" element={<SentInvitationsPage />} />
      <Route path="/profile" element={<ProfilePage/>} />
      <Route path="/contacts_added" element={<AddedContactsPage/>} />
      <Route path="/Privacy_Policies" element={<PrivacyAndPolicies/>} />
      <Route path="/Terms" element={<TermsOfTheServices/>} />
    </Routes>
    </UserProvider>
    </PlanProvider>
      </ContactsProvider>
  
    </RegionsProvider>
  );
}

export default App;



