import React from 'react'
import Button from '../../components/Buttons/Button'
import colors from '../../components/Colors/color'
import SearchBar from '../../components/SearchBar/SearchBar'
import { useContactsContext } from '../../hooks/ContantsContext'
import Table from '../../components/Tables/Table'
import { usePlansContext } from '../../hooks/PlansContext'
import {useRegionsContext} from "../../hooks/RegionsContext"
import { grey } from '@mui/material/colors'

function SentInvitationInfo() {
    const {mySentInvitation ,selectedSentInvitation} = usePlansContext();
    const {contacts} = useContactsContext();
    const {areas} = useRegionsContext()
    const contactsColumns = [' ', 'الاسم', 'المدينة'];
    const contactsData =
        // searchResults && searchResults.length > 0 ? searchResults.map((contact) => [
        //     <input type="checkbox" className='select_to_invitees' onChange={() => handleSelectInvitee(contact)} checked={selectedInvitees.some(invitee => invitee.id === contact.id)} />,
        //     <div>{contact.firstname} ابن {contact.father_name} {contact.lastname}</div>,
        //     contact.phone, contact.area.name,
        // ]) :
            mySentInvitation && selectedSentInvitation && selectedSentInvitation.Contacts
             && selectedSentInvitation.Contacts.map((contact) => [
                // mySentInvitation && contacts 
                // && contacts.map((contact) => [
                // <div className='reply_type'>
                //     {invitation.request_confirmation_of_receipt_of_invitation === "1" ?
                //      <input type="checkbox" className=''/>
                //       : <input type="checkbox" className=''/>}
                   
                // </div>,
                <div>
                       
                </div>,
                <div>
                    {contact.firstname} ابن {contact.father_name} {contact.lastname}</div>,
               <div>
      {contact.area && contact.area[0].id === 1 ? contact.areas_notes : contact.area[0].ar_name}
    </div>    
            ]);


console.log("selectedSentInvitation  ",  selectedSentInvitation)
  return (
    <div className='my_container'>
    <div className='inline_buttons'>
        <div className='right_section'>
        <p className='title_text'> 
            اسم العريس :
            <span>
                {selectedSentInvitation.invite.bridegroom_name}
            </span> 
        </p>
            <p className='title_text'>
                اسم العروس :
                <span> {selectedSentInvitation.invite.bride_name} </span> 
            </p>
            <div className=".right_section-">

            </div>
        </div>
        <div className='left_section'>
        <p className='title_text'>
                 موعد العرس :
                 {/* <br/> */}
                 <span>{selectedSentInvitation.invite.Wedding_date.split('T')[0]}</span> 
            </p>
        </div>
    </div>
    <div className='content'>
    <div className='title_text'>نوع الدعوة : <span>
                        {selectedSentInvitation.invite.invitation_type === "hall_invite" ? "دعوة إلى الصالة":"دعوة إلى المنزل"}</span> </div>
                        <Table
                          tableClassName={"with_edit"}
                            responsiveClassName={'conatact_height_2'}
                            columns={contactsColumns}
                            data={contactsData}
                        />
    </div>
    </div>
  )
}

export default SentInvitationInfo
