import React, { useEffect, useState } from 'react';
import './Slider.css'; // Import your CSS file for styling
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css'; // Import default carousel styles
import { usePlansContext } from '../../hooks/PlansContext';

function ProfileSlider({ onSelectSection }) {
  const {selectedInvitation , setSelectedInvitation} = usePlansContext();
  const [selected_section , setSelectedSection] = useState(null);
  // console.log("selectedInvitation " ,selectedInvitation)
  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 10 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 8 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 4 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 2 }
  };
  
  const profille_Sections = [
    // { id: 1, text: "المعلومات الشخصية" },
    { id: 2, text: "الباقات" }
  ];

  const handleSectionSelect = (section) => {
    setSelectedSection(section);
    onSelectSection(section) 
  };
  
  useEffect(() => {
    // Set default section on component mount
    setSelectedSection(profille_Sections.find(section => section.id === 2));
  }, []); // Run this effect only once on component mount

  return (
    <div className='slider-container'>
      <div className='invitation-slider'>
        {profille_Sections && profille_Sections.length > 0 ? (
          <Carousel
            responsive={responsive}
            dotListClass="custom-dot-list-style"
            arrows={false}
            rtl={true}
          >
            {profille_Sections.map(section => (
              <div key={section.id}
              className={`invitation-slider-item ${selected_section && selected_section.id === section.id ? 'selected' : ''}`}
              onClick={() => handleSectionSelect(section)}
              >
                {section.text}
              </div>
            ))}
          </Carousel>
        ) : (
          // If there are no invitations, render a placeholder or fallback content
          <div>No Invitations available</div>
        )}
      </div>
    </div>
  );
}

export default ProfileSlider;
