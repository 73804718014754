import React from 'react';
import './Slider.css'; // Import your CSS file for styling
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css'; // Import default carousel styles
import { usePlansContext } from '../../hooks/PlansContext';

function InvitationsSlider({invitations_array}) {
  const {selectedInvitation , setSelectedInvitation} = usePlansContext();
  console.log("selectedInvitation " ,selectedInvitation)
  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 10 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 8 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 4 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 2 }
  };



  const handleInvitationSelect = (invitation) => {
    setSelectedInvitation(invitation);
  };

  return (
    // <div className='slider-container'>
      // <div className='invitation-slider'>
      <>
       <div className="sent-invitation-slider">
               {invitations_array && invitations_array.length > 0 ? (
          <Carousel
            responsive={responsive}
            // dotListClass="custom-dot-list-style"
            // arrows={false}
            rtl={true}
          >
            {invitations_array.map(invitation => (
              <div key={invitation.id}
              className={`invitation-slider-item ${selectedInvitation && selectedInvitation.id === invitation.id ? 'selected' : ''}`}
              onClick={() => handleInvitationSelect(invitation)}
              >
                {invitation.text}
              </div>
            ))}
          </Carousel>
        ) : (
          // If there are no invitations, render a placeholder or fallback content
          <div>No Invitations available</div>
        )}
      {/* </div> */}
    {/* </div> */}
    </div>
 
    </>
  );
}

export default InvitationsSlider;
