import React from 'react'
import logo from '../assets/images/Mask group.svg'
import whitelogo from "../assets/images/Mask group white.svg"
import "./AuthLayout.css"
import colors from '../components/Colors/color'
import Button from '../components/Buttons/Button'
import { useNavigate } from 'react-router-dom'

function AuthLayout({ children }) {
  const navigate =useNavigate();
  const goToPrivacy = ()=>{
    navigate('/Privacy_Policies');
  }
  const goToTerms =()=>{
    navigate('/Terms');
  }
  return (
    <div className='container-fluid auth-home'>
      <div className="login-page-logo" style={{backgroundColor:colors.gold}}>
        
        <img src={logo} className='ring' alt="logo" />
        <div>
        {children}
        <div className='terms'>
      <Button
       t_color={colors.white}
       b_color={colors.tab_gold}
      className="terms-btn"
      onClick={goToPrivacy}
      >
        الخصوصية
      </Button>
      <Button
       t_color={colors.white}
       b_color={colors.tab_gold}
      className="terms-btn"
      onClick={goToTerms}
      >
        الشروط
      </Button>
      </div>
        </div>
        
      </div>
      
      <div className='white-logo'>
    
        <img src={whitelogo} className='logo' alt="logo" />
      </div>
    </div>
  )
}

export default AuthLayout
