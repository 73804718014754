import React from 'react';
import Button from '../../components/Buttons/Button';
import Color from '../../components/Colors/color';
import './TermsAndPoliciesStyles.css';
import { useNavigate } from 'react-router-dom';

const TermsOfTheServices = () => {
    const navigate =useNavigate();
    const handleGoBack = () =>{
        navigate("/profile")
    }
    return (
        <div className='rtl-container'>
             <div className="temrs_head">
            <h2>شروط المرافقة لشراء الباقات </h2>
            <Button 
            b_color={Color.light_grey}
            t_color={Color.white}
            className="prev_tap_btn"
            onClick={handleGoBack}
            > رجوع </Button>
            </div>
        <div className="terms-container">
           
          
            <p style={{fontWeight:"700"}}>
            <div style={{fontSize:"20px" , fontWeight:"700"}}>الشروط المرافقة لشراء باقة عزيمة :</div>
            <div className='section' style={{fontSize:"16px"}}>
            1-	يلتزم مستخدم خدمة عزيمة بجميع شروط الاستخدام والخصوصية الواردة في ملف شروط الاستخدام والخصوصية الخاص في الموقع.
<br/>2-	بنود اساسية للتذكير:- 
</div>
<div className='inner_section'>
a.	يتم استخدام خدمة الموقع لإرسال بطاقات عزيمة لمناسبات خاصه فقط، ويمنع منعا باتاً استخدام الخدمة لأي غرض اخر، بما في ذلك لإرسال أي محتوى اعلاني. 
<br/>b.	معلوم لمستخدم الموقع ان إدارة الموقع تقوم بمعاينة وفحص جميع الدعوات المرسلة بهدف الموافقة عليها قبل الارسال. 

            </div>
            <div style={{fontSize:"20px" , fontWeight:"700"}}>
الشروط المرافقة لشراء مساحة إعلان:
</div>
<div className='section' style={{fontSize:"16px" , fontWeight:"700"}}>
1-	يلتزم مستخدم خدمة الاعلان بجميع شروط الاستخدام والخصوصية الواردة في ملف شروط الاستخدام والخصوصية الخاص في الموقع.
<br/>2-	بنود اساسية للتذكير:- 
</div>
<div className='inner_section'> 
a.	معلوم لمستخدم الموقع ان إدارة الموقع تقوم بمعاينة وفحص جميع الإعلانات والمضامين الموضوعة في مساحة المعلن الخاصة بهدف الموافقة عليها قبل إعلانها على الموقع. 
</div>
<div style={{fontSize:"20px" , fontWeight:"700"}}>
الشروط المرافقة لإضافة ارقام على قوائم العزيمة:
</div>
	أوافق على ارسال رسائل عزيمة على رقمي الخاص والذي قد قمت بوضعه على هذا الرابط. 
<br/>	اعلم ان ارسال معلومات خاطئة بشكل مقصود او ارسال معلومات لأشخاص اخرين قد تعرضني للمحاسبة القانونية. 


  
              
            </p>
        </div>
        </div>
    );
};

export default TermsOfTheServices;
