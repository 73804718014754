import React, { useEffect, useState } from 'react';
// import "../Invitations/InvitationsStyles.css"
import PackageCard from '../Packages/PackageCard'
import Pagination from '../../components/Paginations/Pagination';
import { usePlansContext } from '../../hooks/PlansContext';
import "./ProfileStyles.css"
import CustomAlert from '../../components/Alert/CustomAlert';
import { useUsersContext } from '../../hooks/UsersContext';
import Button from '../../components/Buttons/Button';
import colors from '../../components/Colors/color';
import { useNavigate } from 'react-router-dom';
import axiosConfig from '../../utils/axiosConfig';
import Cookies from 'js-cookie';

function Packages() {
    const {  plans,refreshPlans,setSelectedPlan,updateSelectedPlan
        , netError, setNetError, netLoading
    } = usePlansContext();
    const{countOfMessage, refreshProfile,
        userNetError,setUserNetError,userNetLoading} =useUsersContext();
    const [currentPage, setCurrentPage] = useState(1);
    const cardsPerPage = 3;

    // Generate array of package cards
    const packageCards = plans && plans.map((plan, index) => (
        // <div className="package-card" key={index}>
            <PackageCard key={index} plan={plan} />
        // </div>
    ));

    // Calculate total number of pages
    const totalPages = Math.ceil(packageCards.length / cardsPerPage);

    // Function to handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    // useEffect(() => {
    //     // Refresh profile if there's a user network error or if user network loading is true
    //     if (userNetError || userNetLoading) {
    //         refreshProfile();
    //     }
    // }, [userNetError, userNetLoading]);

    useEffect(() => {
        // console.log(countOfMessage);
    }, [countOfMessage]);

    // Calculate start and end index of cards to display on current page
    const startIndex = (currentPage - 1) * cardsPerPage;
    const endIndex = startIndex + cardsPerPage;

    // Slice the packageCards array to display only the cards for the current page
    const visibleCards = packageCards.slice(startIndex, endIndex);

    const handleNetError = () => {
        setNetError(null);
        refreshPlans();
    };

    const handleUserNetError = () => {
        setUserNetError(null);
        refreshProfile();
    };

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const logoutSubmit = async (e) => {
        e.preventDefault();
        try {
          setLoading(true);
          const res = await axiosConfig.post('/user/logout');
          if (res.status === 200 && res.data.success === 1) {
            localStorage.removeItem('invitationsArray');
            localStorage.removeItem('iterator');
            Cookies.remove('access_token');
            navigate('/login');
          } else {
            setError('Logout failed');
          }
        } catch (error) {
          setError('An error occurred during logout');
        } finally {
          setLoading(false);
        }
      };
      const handleErrorClose = () => {
        setError(null);
      };

    return (
        <>
        <div className='profile_container'>
            <div className='up_section'>
            <div className='logout-container'>
                <Button
                t_color={colors.white}
                b_color={colors.tab_gold}
                className="logout-btn"
                onClick={logoutSubmit}
              >
                {loading ? "يتم تسجيل الخروج..." : "تسجيل الخروج"}
              </Button>
                </div>
            <h3>معلومات الباقات</h3>
              
               
                <p className='packages_count'>لديك الأن
                <span>
                {countOfMessage}
                    </span> دعوة في رصيدك</p>
            </div>
            <div className='down_section'>
                <h3>يمكنك شراء المزيد من الدعوات عبر الباقات التالية</h3>
{ netLoading ? (
                                <p>جاري التحميل...</p>
                            ) : netError ? (
                                <div>
                                    <CustomAlert
                                        message={netError}
                                        btnName="أعد المحاولة"
                                        onClose={handleNetError}
                                    />
                                </div>
                            ) : userNetError ? (
                                <div>
                                    <CustomAlert
                                        message={userNetError}
                                        btnName="أعد المحاولة"
                                        onClose={handleUserNetError}
                                    />
                                </div>
                            ) 
                            // : loading ? (
                            //     <p>جاري البحث...</p>
                            // ) 
                            : visibleCards.length > 0 ? (
                                <div className='packages_content'>
                                <div className='packages'>
                                    <div className="plan_cards">
                                        {visibleCards}
                                    </div>
                                </div>
                                <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
                            </div>
                            ):(
"لا يوجد باقات حالياً"
                            )}

              
                {/* <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} /> */}
            </div>
        </div>
        {error !== null && <CustomAlert message={error} onClose={handleErrorClose} />}
        </>
    )
}

export default Packages;
