import React, { useEffect, useState } from 'react'
import AuthLayout from '../../layouts/AuthLayout'
import axios from "../../utils/axiosConfig"
import { FaUser } from "react-icons/fa6";
import { FaLock } from "react-icons/fa";
import './LoginFrom.css'
import Button from '../../components/Buttons/Button';
import colors from '../../components/Colors/color';
import Form from '../../components/Forms/Form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Edit from "../../assets/icons/GoldEdit.svg"
import Cookies from 'js-cookie';
import CustomAlert from '../../components/Alert/CustomAlert';
import { usePlansContext } from '../../hooks/PlansContext';
import FormFetcher from './FormFetcher';
import { useUsersContext } from '../../hooks/UsersContext';


function LoginFrom() {
    
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error ,setError]=useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const{  authenticatedMsg ,setAuthenticatedMsg} =useUsersContext();
//   const [navigated, setNavigated] = useState(false);

//   useEffect(() => {
//     const pathname = location.pathname;
//     const accessToken = searchParams.get('access_token');
//     const cookieAccessToken = Cookies.get('access_token');
//     debugger;
//     if (pathname === '/login' && accessToken) {
//         if (!cookieAccessToken) {
//             console.log('Access Token:', accessToken); // Print the access token

//             if (searchParams.size === 1) {
//                 Cookies.set('access_token', accessToken, { expires: 1 });
//                 console.log("Access token saved in cookies");
//             }
//             setNavigated(true);
//             navigate('/profile');
//         }
//     }
// },[navigated]);

// useEffect(() => {
//   console.log("navigated" ,navigated);
// },[navigated])


  const handleError = () =>{
    alert(error);
  }
  const handleErrorClose = () => {
    setError(null);
  };
  const handleAuthenticatedClose = () => {
    setAuthenticatedMsg(null);
  };
    const fields = [
      { placeholder:' ادخل ايميل المستخدم',type: 'text', name: 'email',className:'login_input' },
      {placeholder:' ادخل كلمة السر ', type: 'password', name: 'password' ,className:'login_input' }
    ];
    const formLinks = [
      { nav: '/register', text: 'ليس لديك حساب؟' },
      // { nav: 'https://api.azimh.com/api/redirect/facebook',target : " _blank", text: 'تسجيل الدخول باستخدام Facebook' },
      // { nav: 'https://api.azimh.com/api/redirect/google', text: 'تسجيل الدخول باستخدام Gmail' },
    ];



    const loginSubmit = async (formData) => {
      console.log("form ", formData);
      try {
        setLoading(true);
        const response = await axios.post('/login', formData);
        console.log("response Log in: ",response);
        
        if (response.status === 200) {
          if (response.data.success === 1) {
            // Login successful
            Cookies.set('access_token', response.data.msg);
            setError(null);
            navigate("/profile");
          } else {
            // Login failed
            console.log("Login failed:", response.data.msg);
            setError(response.data.msg);
          }
        } else {
          console.log("Unexpected response status:", response.status);
          setError('Unexpected response from server');
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          console.log("Error response status 401: ", error.response.status);
         setAuthenticatedMsg("Check your email please to verify your account");
      } else {
        console.error('Error occurred during login:', error);
        setError('حدث خطأ أثناء تسجيل الدخول، يرجى إعادة المحاولة');
      }
      } finally {
        setLoading(false);
      }
    };

  return (
    <>
    <div className='login_buttons'>
      <a href='https://api.azimh.com/api/redirect/facebook' className='facebook-btn'>
      <img src="https://img.icons8.com/color/16/000000/facebook.png" alt="Facebook logo" />
      تسجيل الدخول فيسبوك
      </a>
      <a href='https://api.azimh.com/api/redirect/google' className='google-btn'>
      <img src="https://img.icons8.com/color/16/000000/google-logo.png" alt="Google logo" />
      تسجيل الدخول جوجل
      </a>
    </div>
   
     <Form
  form_className="login-form-container"
  form_title="تسجيل الدخول"
   fields={fields}
   showLink={true}
  links={formLinks}
   btn_className="login-btn"
   btn_text={loading ? "يتم تسجيل الدخول..." : "تسجيل الدخول"}
   disabled={loading}
   onSubmit={loginSubmit}
  />
 {/* <FormFetcher formUrl={formUrl} /> */}
         
  {error !== null && <CustomAlert message={error}  onClose={handleErrorClose} />}
  {authenticatedMsg !== null && <CustomAlert message={authenticatedMsg}  onClose={handleAuthenticatedClose} />}
    </>
 
  )
}

export default LoginFrom
