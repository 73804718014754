import React, { useState } from 'react';
import "./HomeLayoutStyles.css"
import SearchBar from '../components/SearchBar/SearchBar';
import colors from '../components/Colors/color';


function TopLayout({ className,title,searchBar,children }) {
    // const [searchResults, setSearchResults] = useState([]);

    // const handleSearch = (query) => {
    //     setSearchResults([query]);
    // };

    return (
        <div className={`top_layout ${className}`} style={{backgroundColor: colors.extra_light_gold}}>
            <div className='contact-head'>
                <p className='title'>{title}</p>
                {searchBar}
                {/* <SearchBar  onSearch={handleSearch}/> */}
            </div>
            <div className='contact-body'>
                {children}
            </div>
        </div>
    );
}

export default TopLayout;
