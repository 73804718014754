import React, { useEffect, useState } from 'react';
import MyModal from '../../components/Modal/MyModal';
import { useNavigate } from 'react-router-dom';
import { useContactsContext } from '../../hooks/ContantsContext';
import axiosConfig from '../../utils/axiosConfig';
import LoadingSpinner from '../../components/Loanding/LoadingSpinner';
import Button from '../../components/Buttons/Button';
import colors from '../../components/Colors/color';
import "./AddedContactStyle.css";
import "../Invitations/InvitationsStyles.css";
import { useRegionsContext } from '../../hooks/RegionsContext';

function EditAddedContact({ onClose, selected_contact }) {
  const { refreshAddedContacts } = useContactsContext();
  const { areas } = useRegionsContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [id, setID] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [father_name, setFather_name] = useState("");
  const [phone, setPhone] = useState("");
  const [areas_id, setAreasId] = useState(selected_contact.area[0].id);
  const [areas_notes, setAreaName] = useState("");

  useEffect(() => {
    if (selected_contact) {
      setID(selected_contact.id);
      setFirstname(selected_contact.firstname);
      setFather_name(selected_contact.father_name);
      setLastname(selected_contact.lastname);
      setPhone(selected_contact.phone);
      setAreasId(selected_contact.area[0].id);
      if (selected_contact.area[0].id === 1) {
        setAreaName(selected_contact.areas_notes);
      }
    }
    console.log("selected_contact changed:", selected_contact);
  }, [selected_contact]);

  const editTheNewContact = async (e) => {
    e.preventDefault();
    if (!firstname || !lastname || !father_name || !phone || !areas_id) {
      setError('لم يتم إدخال جميع الحقول');
      return;
    }
    setLoading(true); // Set loading state to true when form is submitted
    try {
      const response = await axiosConfig.put(`/contact/edit`, {
        id,
        firstname,
        father_name,
        lastname,
        phone,
        areas_id,
        areas_notes
      });
      console.log(response);
      onClose();
      setError(null);
    } catch (error) {
      if (error.response.status === 422) {
        console.log(error.response.data.errors);
        setError("رجاء أدخل الحقول بشكل الصحيح");
      } else {
        console.log(error.response.data.message);
        setError("رجاء أدخل الحقول بشكل الصحيح");
      }
    } finally {
      setLoading(false); // Set loading state back to false after request completes
    }
  };

  const handleAreasIdChange = (e) => {
    const newAreasId = e.target.value;
    setAreasId(newAreasId);
    if (newAreasId !== "1") {
      setAreaName("");
    }
  };

  return (
    <div className='modal-categories'>
      <MyModal
        show={true}
        onHide={onClose}
        modal_classname="add_Contact"
        body={
          <div>
            {loading ? (
              <LoadingSpinner /> // Render loading spinner if data is loading
            ) : (
              <form className="form-grid-modal">
                <div className='add_title'>تعديل جهة الاتصال</div>
                <div className='column'>
                  <div className="form-group-modal">
                    <input
                      type="text"
                      required
                      className="form-control-modal"
                      name="firstname"
                      placeholder="الاسم"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                    />
                  </div>
                  <div className="form-group-modal">
                    <input
                      type="text"
                      required
                      className="form-control-modal"
                      name="lastname"
                      placeholder="العائلة"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                    />
                  </div>
                  <div className="form-group-modal">
                    <div className="small-select-container">
                      <div>
                        <select
                          value={areas_id}
                          className="form-control-modal"
                          onChange={handleAreasIdChange}
                        >
                          <option value={" "} disabled>العنوان..</option>
                          {areas.map((area, key) => (
                            <option value={area.area.id} key={key}>
                              {area.area.ar_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='column'>
                  <div className="form-group-modal">
                    <input
                      type="text"
                      required
                      className="form-control-modal"
                      name="father_name"
                      placeholder="اسم الأب"
                      value={father_name}
                      onChange={(e) => setFather_name(e.target.value)}
                    />
                  </div>
                  <div className="form-group-modal">
                    <input
                      type="text"
                      required
                      className="form-control-modal"
                      name="phone"
                      placeholder="رقم الهاتف"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  {(areas_id === "1" || areas_notes) && (
                    <div className="form-group-modal">
                      <input
                        type="text"
                        required
                        className="form-control-modal"
                        name="areas_notes"
                        placeholder="اسم المدينة "
                        value={areas_notes}
                        onChange={(e) => setAreaName(e.target.value)}
                      />
                    </div>
                  )}
                  {error && (
                    <div className="error-container">
                      <div className="error-message">{error}</div>
                    </div>
                  )}
                  <div className='save-btn-modal'>
                    <Button
                      className="add_btn"
                      b_color={colors.light_gold}
                      t_color={colors.white}
                      onClick={editTheNewContact}
                    >
                      تعديل
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </div>
        }
      />
    </div>
  );
}

export default EditAddedContact;
