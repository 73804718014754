import React, { useState } from 'react'
import "./PackagesStyles.css"
import Button from '../../components/Buttons/Button';
import colors from '../../components/Colors/color';
import "../../components/Forms/FormStyles.css"
import axiosConfig from '../../utils/axiosConfig';
import { usePlansContext } from '../../hooks/PlansContext';
import CustomAlert from '../../components/Alert/CustomAlert';

function PackageCard({plan}) {
  const {countOfMessage , setCountOfMessage} =usePlansContext();
  const [loading,setLoading] = useState(false);
  const [error,setError] = useState(null);
  const [success,setSuccess] = useState(null);
  const [id, setID] = useState("");


  const handleErrorClose = () => {
    setError(null);
  };
  const handleSuccessClose = () => {
    setSuccess(null);
  };

  const handleBuyPlan = async () => {
    const formData = new FormData();
    formData.append("id" ,plan.id)
    try {
      setLoading(true);
      const response = await axiosConfig.put(`/plan/buyPlan`,formData); // Adjust the API endpoint as needed
      console.log("Buy Plan"); // Handle the response data as needed
      // console.log(response); // Handle the response data as needed
      // setCountOfMessage(response.data.data.count_of_message)
      if(response.data.success === true){
        setSuccess("تم ارسال بريد الدفع الى بريدك الالكتروني يرجى اتمام عملية الدفع")
      }else if(response.data.success === false){
        setSuccess("لم تنجح عملية الشراء")
      }
      setError(null)
    } catch (error) {
      console.error('Error occurred during Buy:', error);
      setError(error)
    }
    finally {
      setLoading(false);
    }
  };
  return (
    <>
          <div className='package_card' style={{backgroundColor:colors.white}}>
        <div className='inner_box' style={{backgroundColor:colors.gold , color:colors.white}}>
      {/* <div className='package_card' style={{backgroundColor:colors.gold , color:colors.white}}> */}
          <h6>{plan.name}</h6>
          <p className='number_message'>{plan.count_of_message}</p>
          <p className='price'>{plan.price}</p>
          <div className='edit_buttons_div'>
          <Button
          className='cansle_from_popup'
          t_color={colors.light_gold}
          b_color={colors.white}
          onClick={() => handleBuyPlan()}
          >
            {loading ? "جار المعالجة.." : "شراء"}
          </Button>
          </div>
       
    </div>
    </div>
    {error !== null && <CustomAlert message={error}  onClose={handleErrorClose} />}
    {success !== null && <CustomAlert message={success}  onClose={handleSuccessClose} />}
    </>
  
  )
}

export default PackageCard
