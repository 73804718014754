import React from 'react';
import './Slider.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css'; // Import default carousel styles
import { usePlansContext } from '../../hooks/PlansContext';
import CustomAlert from '../Alert/CustomAlert';

function SentInvitationsSlider() {
  const {selectedInvitation , setSelectedInvitation ,mySentInvitation,refreshSentInvitation,
    selectedSentInvitation , setSelectedSentInvitation
  } = usePlansContext();
  console.log("selectedSentInvitation " ,selectedSentInvitation)

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
// const invite =[
//   {id :1 ,name:11},
//   {id :1 ,name:11},
//   {id :1 ,name:11},
//   {id :1 ,name:11},
//   {id :1 ,name:11},
//   {id :1 ,name:11},
//   {id :1 ,name:11},
//   {id :1 ,name:11},
//   {id :1 ,name:11},
//   {id :1 ,name:11},
//   {id :1 ,name:1444},
// ]
  const handleInvitationSelect = (invitation) => {
    setSelectedSentInvitation(invitation);
    console.log("mySent selec" , selectedSentInvitation)
  };
  
  return (
    <>
      <div className="sent-invitation-slider">

     
    { mySentInvitation && mySentInvitation.length > 0  ?(
<Carousel
    responsive={responsive}
    // dotListClass="custom-dot-list-style"
    arrows={false}
    rtl={true}
  >
    {mySentInvitation.map(invitation => (
      <div key={invitation.id}
      className={`invitation-slider-item ${selectedSentInvitation && selectedSentInvitation.invite.id === invitation.invite.id ? 'selected' : ''}`}
      onClick={() => handleInvitationSelect(invitation)}
      >
       {invitation.invite.title}
       {/* {invitation.name} */}
      </div>
    ))}

  </Carousel>
    )
    :(
      <p style={{padding:"10px"}}>لا يوجد دعوات مرسلة</p>
    )
   }
    </div>
  </>
  );
}

export default SentInvitationsSlider;
