import React, { useState } from 'react'
import Button from '../../components/Buttons/Button'
import colors from '../../components/Colors/color'
import "./InvitationsStyles.css"
import SearchBar from '../../components/SearchBar/SearchBar'
import Table from '../../components/Tables/Table'
import { useContactsContext } from '../../hooks/ContantsContext'
import axiosConfig from '../../utils/axiosConfig'
import { usePlansContext } from '../../hooks/PlansContext'
import Form from '../../components/Forms/Form'
import { Placeholder } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import AddNewContact from '../AddedContacts/AddNewContact'
import EditIcon from "../../assets/icons/Edit.svg"
import "../../components/Forms/FormStyles.css"
import CustomAlert from '../../components/Alert/CustomAlert'
import LoadingSpinner from '../../components/Loanding/LoadingSpinner'

function CreateInviteSteps({ onSentSuccess }) {
    const { contacts, addedContacts, refreshContacts, refreshAddedContacts,
        allContactNetError, setAllContactNetError, allContactNetLoading,
        contactNetError, setContactNetError, contactNetLoading } = useContactsContext();
    const { selectedInvitation, setSelectedInvitation, refreshSentInvitation } = usePlansContext();
    const [showContact, setShowContact] = useState(true);
    const [showMyContact, setShowMyContact] = useState(false);
    const [showInvitees, setShowInvitees] = useState(false);
    const [showInvitation, setShowInvitation] = useState(false);
    const [activeButton, setActiveButton] = useState('contact');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [selectedInvitees, setSelectedInvitees] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [filteredInvitees, setFilteredInvitees] = useState(selectedInvitees);

    // invitations fields
    const [bride_name, setBride_name] = useState('');
    const [bridegroom_name, setBridegroom_name] = useState('');
    const [Invitation_type, setInvitation_type] = useState('home_invite');
    const [Wedding_date, setWedding_date] = useState('');
    const [request_confirmation_of_receipt_of_invitation, setReceipt_of_invitation] = useState(0);
    const [request_confirmation_of_attendance_from_Invites, setAttendance_from_Invites] = useState(0);
    const [contactsList, setContsctsList] = useState([]);
    const [image, setImage] = useState('');
    const [description, setdescription] = useState('');
    const [title, setTitle] = useState('');

    const handelContact = () => {
        setActiveButton('contact');
        setShowContact(true);
        setShowMyContact(false)
        setShowInvitees(false);
        setShowInvitation(false);
    }
    const handelMyContact = () => {
        setActiveButton('my_contact');
        setShowContact(false);
        setShowMyContact(true)
        setShowInvitees(false);
        setShowInvitation(false);
    }
    const handeInvitees = () => {
        setActiveButton('invitees');
        setShowContact(false);
        setShowMyContact(false)
        setShowInvitees(true);
        setShowInvitation(false);
    }
    const handelInvitation = () => {
        setActiveButton('invitation');
        setShowContact(false);
        setShowMyContact(false);
        setShowInvitees(false);
        setShowInvitation(true);
        console.log()
    }
    const handelNextTap = () => {
        if (showContact) {
            handelMyContact();
        } else if (showMyContact) {
            handeInvitees();
        } else if (showInvitees) {
            handelInvitation();
        }
    }
    const handelPrevTap = () => {
        if (showMyContact) {
            handelContact();
        } else if (showInvitees) {
            handelMyContact();
        }
        else if (showInvitation) {
            handeInvitees();
        }
    }

    const [searchResults, setSearchResults] = useState([]); // State to store search results
    const handleSearch = async (query) => {
        setLoading(true);
        if (!query) {
            setLoading(false);
            setSearchResults(null); // If there's no query, clear search results
        } else {
            try {
                const response = await axiosConfig.get(`/contact/view?search=${query}`); // Replace with your search API endpoint
                setSearchResults(response.data); // Set search results from API response
                console.log(response.data, "res");
            } catch (error) {
                console.error('Error searching Contacts:', error);
                // Handle error
            }
            finally {
                setLoading(false); // Set loading state to false after search completes
            }
        }
    };

    const handleSelectInvitee = (contact) => {
        // Check if the contact is already selected
        const index = selectedInvitees.findIndex(invitee => invitee.id === contact.id);
        if (index === -1) {
            setSelectedInvitees([...selectedInvitees, contact]); // Add the contact to the selected invitees list
            setParticipants([...participants, { id: contact.id }]); // Add the contact to the selected invitees list
            // setProductsList([...productsList, { products_id: products_id, quantity: selectedQuantity }]);
        } else {
            // If the contact is already selected, remove it from the list
            setSelectedInvitees(selectedInvitees.filter(invitee => invitee.id !== contact.id));
            setParticipants(participants.filter(invitee => invitee.id !== contact.id));
        }
    };

    const contactsColumns = [' ', 'الاسم', 'المدينة'];
    const contactsData =
        searchResults && searchResults.length > 0 ? searchResults.map((contact) => [
            <div className='select_input_table'>
                <input type="checkbox" onChange={() => handleSelectInvitee(contact)} checked={selectedInvitees.some(invitee => invitee.id === contact.id)} />
            </div>,
            <div>{contact.firstname} ابن {contact.fathername} {contact.lastname}</div>,
            <div>
                {contact.area && contact.area[0].id === 1 ? contact.areas_notes : contact.area[0].ar_name}
            </div>
        ]) :
            contacts && contacts.map((contact) => [
                <div className='select_input_table'>
                    <input type="checkbox" onChange={() => handleSelectInvitee(contact)} checked={selectedInvitees.some(invitee => invitee.id === contact.id)} />
                </div>,
                <div>
                    {contact.firstname} ابن {contact.fathername} {contact.lastname}</div>,
                <div>
                    {contact.area && contact.area[0].id === 1 ? contact.areas_notes : contact.area[0].ar_name}
                </div>
            ]);

    const myContactsColumns = [' ', 'الاسم', 'المدينة'];
    const myContactsData =
        // searchResults && searchResults.length > 0 ? searchResults.map((contact) => [
        //     <input type="checkbox" className='select_to_invitees' onChange={() => handleSelectInvitee(contact)} checked={selectedInvitees.some(invitee => invitee.id === contact.id)} />,
        //     <div>{contact.firstname} ابن {contact.father_name} {contact.lastname}</div>,
        //     contact.phone, contact.area.name,
        // ]) :
        addedContacts && addedContacts.map((myContact) => [
            <div className='select_input_table'>
                <input type="checkbox" onChange={() => handleSelectInvitee(myContact)} checked={selectedInvitees.some(invitee => invitee.id === myContact.id)} />
            </div>,
            <div>
                {myContact.firstname} ابن {myContact.father_name} {myContact.lastname}</div>,
            <div>
                {myContact.area && myContact.area[0].id === 1 ? myContact.areas_notes : myContact.area[0].ar_name}
            </div>
        ]);

    const inviteesColumns = [' ', 'اسم المسستخدم', 'العنوان'];
    const inviteesData =
        filteredInvitees && filteredInvitees.length > 0 ? filteredInvitees.map((contact) => [
            <div className='select_input_table'>
                <input type="checkbox" onChange={() => handleSelectInvitee(contact)} checked={selectedInvitees.some(invitee => invitee.id === contact.id)} />        </div>,
            <div>{contact.firstname} ابن {contact.father_name} {contact.lastname}</div>,
            <div>
                {contact.area && contact.area[0].id === 1 ? contact.areas_notes : contact.area[0].ar_name}
            </div>
        ]
        ) :
            selectedInvitees && selectedInvitees.map((contact) => [
                <div className='select_input_table'>
                    <input type="checkbox" onChange={() => handleSelectInvitee(contact)} checked={selectedInvitees.some(invitee => invitee.id === contact.id)} />        </div>,
                <div>{contact.firstname} ابن {contact.father_name} {contact.lastname}</div>,
                <div>
                    {contact.area && contact.area[0].id === 1 ? contact.areas_notes : contact.area[0].ar_name}
                </div>
            ]);

    const navigate = useNavigate();
    const handleClick = () => {
        // Navigate to the desired route when the button is clicked
        navigate('/contacts_added');
    };

    const [addNewContact, setAddNewContact] = useState(false);
    const openByPopup = () => {
        setAddNewContact(true)
        //    console.log("remi")
    }
    const closeAddPopup = () => {
        setAddNewContact(false);
        handelMyContact();
    };

    function ImagechangeHandler(e) {
        const file = e.target.files[0];
        setImage(file);
        // console.log("image   ", image)
    }

    const handleErrorClose = () => {
        setError(null);
    };
    const handleSuccessClose = () => {
        setSuccess(null);
        setSelectedInvitation(null);
    };

    const handleSendInvitation = async (e) => {
        // e.preventDefault();
        setLoading(true); // Set loading state to true when form is submitted
        const invitationFormData = new FormData();
        invitationFormData.append('bridegroom_name', bridegroom_name);
        invitationFormData.append('bride_name', bride_name);
        invitationFormData.append('invitation_type', Invitation_type);
        invitationFormData.append('Wedding_date', Wedding_date);
        invitationFormData.append('image', image);
        invitationFormData.append('title', title);
        invitationFormData.append('description', description);
        invitationFormData.append('request_confirmation_of_attendance_from_Invites', request_confirmation_of_attendance_from_Invites);
        invitationFormData.append('request_confirmation_of_receipt_of_invitation', request_confirmation_of_receipt_of_invitation);
        participants.forEach((invitee, index) => {
            invitationFormData.append(`contacts[${index}][id]`, invitee.id);
        });
        // if(areas_id === "1"){
        //   contactFormData.append('areas_id', areas_id);
        // }
        console.log(invitationFormData.request_confirmation_of_attendance_from_Invites)
        try {
            const { data } = await axiosConfig.post(`/invitation/add`, invitationFormData,
                {
                    headers: {
                        // ...axiosInstance.defaults.headers, // Use default headers
                        'Content-Type': 'multipart/form-data', // Override Content-Type if needed
                    }
                }
            );
            console.log(data.message);
            console.log(data.data);
            setSuccess("تم الإرسال بنجاح...")
            onSentSuccess();
            refreshSentInvitation();
            // refreshAddedContacts()
            // onClose()
        } catch (error) {
            if (error.response.status === 422) {
                console.log(error.response.data.errors);
                setError("رجاء أدخل الحقول بشكل الصحيح");
            } else {
                console.log(error.response.data.message);
                // setError(error.response.data.message);
                setError("رجاء أدخل الحقول بشكل الصحيح");
            }
        } finally {
            setLoading(false); // Set loading state back to false after request completes
        }
    }

    const handleAllContactNetError = () => {
        setAllContactNetError(null);
        refreshContacts();
    }
    const handleContactNetError = () => {
        setContactNetError(null);
        refreshAddedContacts();
    }

    const handleInviteesSearch = (query) => {
        if (!query) {
            setFilteredInvitees(selectedInvitees);
        } else {
            setFilteredInvitees(selectedInvitees.filter(invitee =>
                invitee.firstname?.toLowerCase().includes(query.toLowerCase()) ||
                invitee.father_name?.toLowerCase().includes(query.toLowerCase()) ||
                invitee.lastname?.toLowerCase().includes(query.toLowerCase()) ||
                (invitee.area && invitee.area[0].ar_name?.toLowerCase().includes(query.toLowerCase())) ||
                (invitee.area && invitee.area[0].id === 1 && invitee.areas_notes?.toLowerCase().includes(query.toLowerCase()))
            ));
        }
    };


    return (
        <div className='my_container'>
            <div className='inline_buttons'>
                <div className='right_section'>
                <div className="right_section-1">
                <Button
                        t_color={activeButton === 'contact' ? colors.white : colors.light_grey}
                        b_color={activeButton === 'contact' ? colors.light_gold : colors.white}
                        onClick={() => handelContact()}
                        className="invitations_btn_myContact"
                    >
                        القائمة
                    </Button>
                    <Button
                        t_color={activeButton === 'my_contact' ? colors.white : colors.light_grey}
                        b_color={activeButton === 'my_contact' ? colors.light_gold : colors.white}
                        onClick={() => handelMyContact()}
                        className="invitations_btn_myContact"
                    >
                        الأسماء المضافة
                    </Button>
                </div>
                <div className="right_section-1">
                <Button
                        t_color={activeButton === 'invitees' ? colors.white : colors.light_grey}
                        b_color={activeButton === 'invitees' ? colors.light_gold : colors.white}
                        onClick={() => handeInvitees()}
                        className="invitations_btn_myContact"
                    >
                        قائمة المدعويين
                    </Button>
                    <Button
                        t_color={activeButton === 'invitation' ? colors.white : colors.light_grey}
                        b_color={activeButton === 'invitation' ? colors.light_gold : colors.white}
                        onClick={() => handelInvitation()}
                        // className="invitations_btn"
                        className="invitations_btn_myContact"
                    >
                        إعداد الدعوة {selectedInvitation.id}
                    </Button>
                </div>
                </div>
                <div className='left_section'>
                    {showContact ? (
                        <SearchBar onSearch={handleSearch} />
                    ) : showInvitees ? (
                        <SearchBar onSearch={handleInviteesSearch} />
                    ) : null}

                </div>
            </div>
            <div className='content'>
                {showContact &&
                    <>
                        {allContactNetLoading ? (
                            <LoadingSpinner />
                        ) : allContactNetError ? (
                            <CustomAlert
                                message={allContactNetError}
                                btnName="أعد المحاولة"
                                onClose={handleAllContactNetError}
                            />
                        ) : contactsData.length > 0 ? (
                            <Table
                                tableClassName={"with_edit"}
                                responsiveClassName={'conatact_height_2'}
                                columns={contactsColumns}
                                data={contactsData}
                            />
                        ) : (
                            <div className='empty-invitation-list'>
                                لا يوجد أسماء
                            </div>
                        )
                        }
                    </>
                }
                {showMyContact &&
                    <>
                        {contactNetLoading ? (
                            <LoadingSpinner />
                        ) : contactNetError ? (
                            <CustomAlert
                                message={contactNetError}
                                btnName="أعد المحاولة"
                                onClose={handleContactNetError}
                            />
                        ) : myContactsData.length > 0 ? (
                            <Table
                                tableClassName={"with_edit"}
                                responsiveClassName={'conatact_height_2'}
                                columns={myContactsColumns}
                                data={myContactsData}
                            />
                        ) : (
                            <div className='empty-invitation-list'>
                                لا يوجد أسماء خاصة بك
                            </div>

                        )
                        }

                    </>
                }
                {showInvitees &&
                    <>

                        {selectedInvitees.length > 0 ? (
                            <>
                                <div className='title_text'>قائمة المدعويين :<span>
                                    {selectedInvitees.length}</span> </div>
                                <Table
                                    tableClassName={"with_edit"}
                                    responsiveClassName={'conatact_height_2'}
                                    columns={inviteesColumns}
                                    data={inviteesData}
                                />
                            </>
                        ) : (
                            <div className='empty-invitation-list'>
                                قائمة المدعويين فارغة
                            </div>
                        )}

                    </>}
                {showInvitation &&

                    <div className='send_invitation_form'>
                        <h2>إعداد الدعوة :</h2>
                        <form>
                            <div className="column-1">

                                <input type="text" name="bridegroom_name"
                                    placeholder="اسم العريس الثلاثي" value={bridegroom_name}
                                    onChange={(e) => { setBridegroom_name(e.target.value) }} />
                                <input type="text" name="bride_name"
                                    placeholder="اسم العروس الثلاثي" value={bride_name}
                                    onChange={(e) => { setBride_name(e.target.value) }} />

                                <div className="select-container">
                                    <select
                                        value={Invitation_type}
                                        onChange={(e) => { setInvitation_type(e.target.value) }}
                                    // className='dropdown-menu'
                                     className="styled-select"
                                    >
                                        <option className="styled-option" value="home_invite">الدعوة إلى المنزل</option>
                                        <option className="styled-option" value="hall_invite">الدعوة إلى الصالة</option>
                                    </select>

                                </div>
                                <div className='title_input'>
                                    <input type="text" name="title"
                                        placeholder="عنوان الدعوة" value={title}
                                        onChange={(e) => { setTitle(e.target.value) }} />
                                    <input type="date" value={Wedding_date}
                                        onChange={(e) => { setWedding_date(e.target.value) }} />
                                </div>
                                {/* <div className='select_input'>
                                    <input type='checkbox' value={request_confirmation_of_receipt_of_invitation}
                                        checked={request_confirmation_of_receipt_of_invitation === 1}
                                        onChange={(e) => setReceipt_of_invitation(e.target.checked ? 1 : 0)} />
                                    <label>طلب تأكيد استلام الدعوة</label>
                                    <input type='checkbox' value={request_confirmation_of_attendance_from_Invites}
                                        checked={request_confirmation_of_attendance_from_Invites === 1}
                                        onChange={(e) => setAttendance_from_Invites(e.target.value ? 1 : 0)} />
                                    <label>طلب تأكيد الحضور من المدعويين </label>
                                </div> */}
                                <div className='select_input'>
    <div className='checkbox_container'>
        <input type='checkbox' value={request_confirmation_of_receipt_of_invitation}
            checked={request_confirmation_of_receipt_of_invitation === 1}
            onChange={(e) => setReceipt_of_invitation(e.target.checked ? 1 : 0)} />
        <label>طلب تأكيد استلام الدعوة</label>
    </div>
    <div className='checkbox_container'>
        <input type='checkbox' value={request_confirmation_of_attendance_from_Invites}
            checked={request_confirmation_of_attendance_from_Invites === 1}
            onChange={(e) => setAttendance_from_Invites(e.target.value ? 1 : 0)} />
        <label>طلب تأكيد الحضور من المدعويين </label>
    </div>
</div>


                            </div>
                            <div className='column-1'>
                                <div
                                 className="file-group"
                                 >
                                    <label htmlFor="file-upload" className="custom-file-upload">
                                        <input id="file-upload" required className="add_image" type="file" name="file"
                                            onChange={ImagechangeHandler}
                                        />
                                        {image ? ( // Check if an image is selected
                                            <img className='selecetedImage' src={URL.createObjectURL(image)} alt="Selected Image" />
                                        ) : (
                                            <img src={EditIcon} alt="Edit Icon" />
                                        )}
                                    </label>
                                </div>
                                <div className='invitation_des'>
                                    <textarea type="text" name="title"
                                        placeholder="وصف الدعوة" value={description}
                                        onChange={(e) => { setdescription(e.target.value) }} />
                                </div>

                            </div>

                        </form>
                    </div>
                }

            </div>
            <div className='inline_buttons'>
                {showContact && !allContactNetError && !allContactNetLoading &&
                    <>
                        <div className='right_section'>
                            <Button
                                t_color={colors.grey}
                                b_color={colors.white}
                                className="add_contact_btn"
                                onClick={openByPopup}
                            >
                                إضافة جهة اتصال
                            </Button>
                        </div>
                        <div className='left_section'>
                            <Button
                                t_color={colors.white}
                                b_color={colors.light_gold}
                                className="next_tap_btn"
                                onClick={() => handelNextTap()}
                            >
                                التالي
                            </Button>
                        </div>
                    </>
                }
                {(showInvitees && selectedInvitees.length > 0 || showMyContact && !contactNetError && !contactNetLoading) &&
                    <>
                        <div className='right_section'>
                            <Button
                                t_color={colors.white}
                                b_color={colors.light_gold}
                                className="prev_tap_btn"
                                onClick={() => handelPrevTap()}
                            >
                                رجوع
                            </Button>
                        </div>
                        <div className='left_section'>
                            <Button
                                t_color={colors.white}
                                b_color={colors.light_gold}
                                className="next_tap_btn"
                                onClick={() => handelNextTap()}
                            >
                                التالي
                            </Button>
                        </div>

                    </>
                }
                {showInvitation &&
                    <>
                        <div className='right_section'>
                            <div className='right-section-1'>
                            <Button
                                t_color={colors.white}
                                b_color={colors.light_gold}
                                className="prev_tap_btn"
                                onClick={() => handelPrevTap()}
                            >
                                رجوع
                            </Button>
                            </div>
                            <div className="right-section-1">
                            <div className='note_text'>سيتم إرسال الدعوة إلى <span>
                            {selectedInvitees.length}</span> جهة اتصال</div>
                            </div>
                           
                        </div>
                        <div className='left_section'>
                       
                            <Button
                                t_color={colors.white}
                                b_color={colors.success}
                                className="send-invitation-btn"
                                onClick={() => handleSendInvitation()}
                            >
                                {loading ? "يتم الإرسال" : "إرسال"}
                            </Button>
                            </div>
                    </>
                }
            </div>
            {error !== null && <CustomAlert message={error} onClose={handleErrorClose} />}
            {success !== null && <CustomAlert message={success} onClose={handleSuccessClose} />}
            {addNewContact && <AddNewContact onClose={closeAddPopup} />}
        </div>

    )
}

export default CreateInviteSteps
