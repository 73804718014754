import React, { useState, useEffect } from 'react';
import MyModal from '../../components/Popup/MyModal';
import axios from '../../utils/axiosConfig';
import { useContactsContext } from '../../hooks/ContantsContext';

function EditContact({ onClose }) {
    const { selectedContant } = useContactsContext();
    const [firstname, setFirstName] = useState("");
    const [father_name, setFatherName] = useState("");
    const [lastname, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (selectedContant) {
            setFirstName(selectedContant.firstname);
            setFatherName(selectedContant.father_name);
            setLastName(selectedContant.lastname);
            setPhone(selectedContant.phone);
        }
        console.log("selectedContant changed:", selectedContant);
    }, [selectedContant]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true)
            await axios.put(`/contacts/${selectedContant.id}`,{
                firstname,
                father_name,
                lastname,
                phone
            }).then(response => {
                console.log(response.data);
                onClose();
              }).catch(error => {
                console.error(error);
              }); 
        } catch (error) {
            console.error('Error occurred during form submission:', error);
            setError(error.response?.data?.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className='modal-categories'>
            <MyModal
                title="Edit Area"
                show={true}
                onHide={onClose}
                body={
                    <>
                        {error && <div className="error-message">{error}</div>}
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>First Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    name="firstname"
                                    value={firstname} 
                                    onChange={(e) => setFirstName(e.target.value)} 
                                />
                            </div>
                            <div className="form-group">
                                <label>Father Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    name="father_name"
                                    value={father_name} 
                                    onChange={(e) => setFatherName(e.target.value)} 
                                />
                            </div>
                            <div className="form-group">
                                <label>Last Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    name="lastname"
                                    value={lastname} 
                                    onChange={(e) => setLastName(e.target.value)} 
                                />
                            </div>
                            <div className="form-group">
                                <label>Phone Number</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    name="phone"
                                    value={phone} 
                                    onChange={(e) => setPhone(e.target.value)} 
                                />
                            </div>
                            <div>
                                <button type="submit" className="btn btn-primary">{loading ? "Updating..." : "Update Contact"}</button>
                                <button type="button" className="btn btn-secondary ml-2" onClick={onClose}>Cancel</button>
                            </div>
                        </form>
                    </>
                }
            />
        </div>
    );
}

export default EditContact;

