import React from 'react'
// import "../pages/Contact/BodyStyles.css";
import "./HomeLayoutStyles.css"
import colors from '../components/Colors/color';

function ButtomLayout({tableClassName,children}) {

  return (
    <div className={tableClassName ? tableClassName : ''} style={{ backgroundColor: colors.extra_light_gold }}>
      {children}
    </div>
  )
}

export default ButtomLayout
