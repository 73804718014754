import React, { useState } from 'react';
import "./SearchBarStyles.css";

function SearchBar({ onSearch }) {
  const [query, setQuery] = useState('');

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSearch(query);
    }
  };

  return (
    <input
      type="text"
      placeholder="بحث ..."
      value={query}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      className='search-input'
    />
  );
}

export default SearchBar;
