import React from 'react'
import InvitationsTabbedNav from '../../components/TabbedNavigation/InvitationsTabbedNav'
import HomeLayout from '../../layouts/HomeLayout'
import TopLayout from '../../layouts/TopLayout'
import SearchBar from '../../components/SearchBar/SearchBar'
import InvitationsSlider from '../../components/Slider/InvitationsSlider'
import Button from '../../components/Buttons/Button'
import colors from '../../components/Colors/color'
import ButtomLayout from '../../layouts/ButtomLayout'
import CreateInviteSteps from './CreateInviteSteps'
import { usePlansContext } from '../../hooks/PlansContext'
import SentInvitationInfo from './SentInvitationInfo'
import SentInvitationsSlider from '../../components/Slider/SentInvitationsSlider'
import CustomAlert from '../../components/Alert/CustomAlert'

function MySentInvitationsPage() {
  const {selectedSentInvitation,  mySentNetError, setMySentNetError,refreshSentInvitation,
   mySentNetLoading} = usePlansContext();

    const handleNetError = () =>{
      setMySentNetError(null);
      refreshSentInvitation();
    }

  return (
    <HomeLayout
    page_title="الدعوات المرسلة"
    mainContent={<>
  <ButtomLayout 
  tableClassName="buttom_full"
  children={   
    <>
    <div className="slider">
      {mySentNetError}
    {/* <InvitationsSlider /> */}
    <SentInvitationsSlider/>
    </div>
    <div className="create-steps">
    { mySentNetLoading ? (
       <p>جاري التحميل...</p>
    ) :mySentNetError ?(
      <CustomAlert
      message={mySentNetError}
      btnName="أعد المحاولة"
      onClose={handleNetError}
    />
    ): 
    selectedSentInvitation ? (
     <SentInvitationInfo />)
     :(
      <p style={{padding:"10px"}}>لم يتم تحديد دعوة</p>
    )
    }
    </div>
     
    </>
}
  />
  
    </>}
    />
  )
}

export default MySentInvitationsPage
