



import React, { useState, useEffect } from 'react';
import MyModal from '../../components/Popup/MyModal';
import { useRegionsContext } from '../../hooks/RegionsContext';
// import createAxiosInstance from "../../utils/axiosConfig"
import axios from '../../utils/axiosConfig';

function EditArea({ onClose }) {
    const { selectedArea ,updateAreasInContext } = useRegionsContext();
    const [ar_name, setArName] = useState("");
    const [en_name, setEnName] = useState("");
    const [he_name, setHeName] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (selectedArea) {
            setArName(selectedArea.ar_name);
            setEnName(selectedArea.en_name);
            setHeName(selectedArea.he_name);
        }
        console.log("selectedArea changed:", selectedArea);
    }, [selectedArea]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true)
            await axios.put(`/areas/${selectedArea.id}`,{
                ar_name,
                en_name,
                he_name
            }).then(response => {
                console.log(response.data);
                onClose();
              }).catch(error => {
                console.error(error);
              }); 
        } catch (error) {
            console.error('Error occurred during form submission:', error);
            setError(error.response?.data?.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className='modal-categories'>
            <MyModal
                title="Edit Area"
                show={true}
                onHide={onClose}
                body={
                    <>
                        {error && <div className="error-message">{error}</div>}
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>Arabic Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    name="ar_name"
                                    value={ar_name} 
                                    onChange={(e) => setArName(e.target.value)} 
                                />
                            </div>
                            <div className="form-group">
                                <label>English Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    name="en_name"
                                    value={en_name} 
                                    onChange={(e) => setEnName(e.target.value)} 
                                />
                            </div>
                            <div className="form-group">
                                <label>Hebrew Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    name="he_name"
                                    value={he_name} 
                                    onChange={(e) => setHeName(e.target.value)} 
                                />
                            </div>
                            <div>
                                <button type="submit" className="btn btn-primary">{loading ? "Updating..." : "Update Area"}</button>
                                {/* <button type="button" className="btn btn-secondary ml-2" onClick={onClose}>Cancel</button> */}
                            </div>
                        </form>
                    </>
                }
            />
        </div>
    );
}

export default EditArea;

