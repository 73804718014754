// AppContext.js

import { useLocation, useNavigate } from "react-router-dom";
import axios from "../utils/axiosConfig"
import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

// Creating a context
const UsersContext = createContext();

// Custom hook to use the context
export const useUsersContext = () => useContext(UsersContext);

// Provider component to wrap around components that need access to the context
export const UserProvider = ({ children }) => {

    const navigate =useNavigate();
    const location = useLocation();

    const [userNetError,setUserNetError] =useState(null);
    const [userNetLoading, setUserNetLoading] = useState(true);

    // Areas variables for regions data and loading status
    const [users, setUsers] = useState([]);
    const [selectedUser , setSelectedUser] = useState(null);
    const [countOfMessage , setCountOfMessage] = useState(0);
        // Function to fetch states in context
    const fetchUsers = async  (keyword = '', page = '', count = '') => {
        try {
            const url = `/users?keyword=${keyword}&page=${page}&count=${count}`;
            const response = await axios.get(url);
            if (response.data.code === 200) {
                // console.log(response.data.message,"get All users");
                // console.log("res: ", response);
                // console.log("res: ", response.data.data.data);
                setUsers(response.data.data.data);
            } else {
                console.log("error ", response.data.message);
            }
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };
    // Function to update states in context
    const updateUsersInContext = (newUser) => {
        setUsers(newUser);
    };
    // Function to refresh states in context
    const refreshUsers = () => {
        fetchUsers();
    };

  //Profile

const [ authenticatedMsg ,setAuthenticatedMsg] =useState(null);

  const fetchProfile = async () => {
    try {
        const response = await axios.get('/user/profile');
        console.log("res profile: ", response);
        if (response.status === 200 && response.data.success === 1) {
                          try {
                    setCountOfMessage(response.data.user.count_of_message);
                    setUserNetError(null);
                } catch (setPlanError) {
                    console.error("Error setting plans:", setPlanError);
                    setUserNetError("حدث خطأ أثناء تحميل عدد الدعوات المناحة. يرجى المحاولة مرة أخرى.");                    }
        } else {
            console.log("error ", response.data);
            setUserNetError("حدث خطأ أثناء تحميل عدد الدعوات المتاحة. يرجى المحاولة مرة أخرى.");
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            console.log("Error response status 401: ", error.response.status);
            if (location.pathname !== '/login' && location.pathname !== '/register') {
                Cookies.remove('access_token');
                navigate('/login');
            }
        } else {
        console.error("Error fetching plans:", error);
        setUserNetError("حدث خطأ أثناء تحميل عدد الدعوات المتاحة. يرجى المحاولة مرة أخرى.");
        }
    }finally{
        setUserNetLoading(false)
    }
};

const refreshProfile = () => {
    setUserNetLoading(true);
    fetchProfile();
};

    useEffect(() => {
        fetchProfile();
    }, []);


    return (
        <UsersContext.Provider
            value={{
             users,refreshUsers,updateUsersInContext,
             selectedUser,setSelectedUser,
             countOfMessage,setCountOfMessage,
             refreshProfile,
             userNetError,setUserNetError,userNetLoading,
             authenticatedMsg ,setAuthenticatedMsg
            }}
        >
            {children}
        </UsersContext.Provider>
    );
};
    