import React, { useState, useEffect } from 'react';
import HomeLayout from '../../layouts/HomeLayout';
import Button from '../../components/Buttons/Button';
import colors from '../../components/Colors/color';
import ButtomLayout from '../../layouts/ButtomLayout';
import Table from '../../components/Tables/Table';
import { useContactsContext } from '../../hooks/ContantsContext';
import AddNewContact from './AddNewContact';
import EditIcon from "../../assets/SVG/Asset 23.svg";
import EditAddedContact from './EditAddedContact';
import { useRegionsContext } from '../../hooks/RegionsContext';
import CustomAlert from '../../components/Alert/CustomAlert';
import LoadingSpinner from '../../components/Loanding/LoadingSpinner';

function AddedContactsPage() {
  const { addedContacts, refreshAddedContacts , contactNetError,setContactNetError,contactNetLoading } = useContactsContext();
  const { netError, setNetError, netLoading, refreshAreas } = useRegionsContext();
  const [selectedContact, setSelectedContact] = useState(null);
  const [editModalShow, setEditModalShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [addNewContact, setAddNewContact] = useState(false);

  useEffect(() => {
    if (netError) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [netError]);

  const openEditMode = (contact) => {
    setEditModalShow(true);
    setSelectedContact(contact);
  };

  const closeEditMode = () => {
    setEditModalShow(false);
    refreshAddedContacts();
  };

  const handleNetError = () => {
    setNetError(null);
    refreshAreas();
  }; 
  const handleContactNetError = () =>{
    setContactNetError(null);
    refreshAddedContacts();
  }


  const openAddPopup = () => {
    if (netError) {
      setShowAlert(true);
    } else {
      setAddNewContact(true);
    }
  };

  const closeAddPopup = () => {
    setAddNewContact(false);
    refreshAddedContacts();
  };

  const contactsColumns = [' ', 'الاسم', 'الرقم', 'المدينة'];
  const contactsData = addedContacts && addedContacts.map((contact) => [
    <div className='select_name'>
      <Button
        className="edit_icon"
        b_color={colors.gold}
        onClick={() => openEditMode(contact)}
      >
        <img src={EditIcon} alt="Edit" />
      </Button>
    </div>,
    <div>
      {contact.firstname} ابن {contact.father_name} {contact.lastname}
    </div>,
    contact.phone,
    <div>
      {contact.area && contact.area[0].id === 1 ? contact.areas_notes : contact.area[0].ar_name}
    </div>
  ]);

  return (
    <HomeLayout
      page_title="جهات الاتصال المضافة"
      mainContent={
        <>
          <ButtomLayout
            tableClassName="buttom_full"
            children={
              <>
                <div className="slider">
                  <div className='inline_buttons_add'>
                    <div className='right_section'>
                      <Button
                        t_color={colors.white}
                        b_color={colors.light_gold}
                        onClick={openAddPopup}
                        className="new_contact_btn"
                      >
                        إضافة اسم جديد
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="create-steps">
                  <div className='content'>
                    {contactNetLoading ? (
                       <p>جاري التحميل...</p>
                    ): contactNetError ? (
                      <CustomAlert
                      message={contactNetError}
                      btnName="أعد المحاولة"
                      onClose={handleContactNetError}
                    />
                    ): addedContacts.length > 0 ? (
                      <Table
                      tableClassName={"with_edit"}
                      responsiveClassName={'conatact_height_3'}
                      columns={contactsColumns}
                      data={contactsData}
                    />
                    ):(
                      <p>لا يوجد جهات اتصال خاصة بك</p>
                    )
                    }
                   
                  </div>
                </div>
                {netLoading && <LoadingSpinner />}
                {showAlert && (
                  <CustomAlert
                    message={netError}
                    btnName="أعد المحاولة"
                    onClose={handleNetError}
                  />
                )}
                {addNewContact && <AddNewContact onClose={closeAddPopup} />}
                {editModalShow && <EditAddedContact onClose={closeEditMode} selected_contact={selectedContact} />}
              </>
            }
          />
        </>
      }
    />
  );
}

export default AddedContactsPage;
