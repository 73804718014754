import React, { useState } from 'react';
import HomeLayout from '../../layouts/HomeLayout';
import Button from '../../components/Buttons/Button';
import colors from '../../components/Colors/color';
import AddContact from './AddContact';
import TopLayout from '../../layouts/TopLayout';
import Table from '../../components/Tables/Table';
import { useContactsContext } from '../../hooks/ContantsContext';
import EditContact from './EditContact';
import EditIcon from "../../assets/icons/GoldEdit.svg"
import axiosConfig from '../../utils/axiosConfig';
import SearchBar from '../../components/SearchBar/SearchBar';

function ContactPage() {
    const { contacts, refreshContacts ,setSelectedContact} = useContactsContext();

    const [addModalShow, setAddModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [loading, setLoading] = useState(true); // Loading state


    const openAddPopup = () => {
        setAddModalShow(true);
    };
    const closeAddPopup = () => {
        setAddModalShow(false);
        refreshContacts();
    };

    const openEditPopup = (contact) => {
        setEditModalShow(true);
        setSelectedContact(contact)
        console.log("contact ", contact)
    };
    const closeEditPopup = () => {
        setEditModalShow(false);
        refreshContacts();
    };

    // Define the content you want to render within Upbar
    const upBarContent = (
        <Button
            t_color={colors.white}
            b_color={colors.light_gold}
            className="new-contact-btn"
            onClick={openAddPopup}
        >
            إضافة جهة اتصال
        </Button>
    );

    const EditButton = (contact) => (
        <>
            <Button
                className="edit_icon"
                onClick={() => openEditPopup(contact)}
            >
                <img src={EditIcon} />
            </Button>
        </>
    );


    const [searchResults, setSearchResults] = useState([]); // State to store search results
    const handleSearch = async (query) => {
        setLoading(true);
        if (!query) {
            setLoading(false);
            setSearchResults(null); // If there's no query, clear search results
        } else {
            try {
                const response = await axiosConfig.get(`/contacts?keyword=${query}`); // Replace with your search API endpoint
                setSearchResults(response.data.data.data); // Set search results from API response
                console.log(response.data.data, "res");
            } catch (error) {
                console.error('Error searching Contacts:', error);
                // Handle error
            }
            finally {
                setLoading(false); // Set loading state to false after search completes
            }
        }
    };



    const columns = ['تاريخ الاشتراك', 'اسم المسستخدم', 'الرقم', 'العنوان'];
    const data = searchResults && searchResults.length > 0 ? searchResults.map((contact) => [
        contact.id,
        <div>{contact.firstname} ابن {contact.father_name} {contact.lastname}</div>,
        contact.phone, contact.area.ar_name,
        EditButton(contact)
    ]) : contacts && contacts.map((contact) => [
        contact.id,
        <div>{contact.firstname} ابن {contact.father_name} {contact.lastname}</div>,
        contact.phone, contact.area.ar_name,
        EditButton(contact)
    ]);

    return (
        <>
            <HomeLayout
                page_title="قائمة الأسماء"
                upBarContent={upBarContent}
                mainContent={
                    <TopLayout
                        className="top_full"
                        // responsiveClassName={'conatact_hieght_2'}
                        title="القائمة"
                        searchBar={<SearchBar  onSearch={handleSearch}/>}
                        children={
                            <Table
                                responsiveClassName={'conatact_height_2'}
                                columns={columns}
                                data={data}
                                
                            />
                        }
                    />}
            />
            {addModalShow && <AddContact onClose={closeAddPopup} />}
            {editModalShow && <EditContact onClose={closeEditPopup} />}

        </>
    );
}

export default ContactPage;
