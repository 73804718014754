import React, { useState } from 'react';
import colors from '../../components/Colors/color';
import "./BodyStyles.css";
import Table from '../../components/Tables/Table';

function ContactTable({ tableClassName,title, columns,data }) {


    return (
        <div 
        // className={`contact-by-area ${tableClassName ? tableClassName : ''}`} 
        // style={{backgroundColor: colors.extra_light_gold}}
        >
            <p className='contant_title'>جهات الاتصال </p>
                <Table 
                responsiveClassName={'conatact_height'}
                tableClassName={'contacts_by_area'} 
                    columns={columns} 
                    data={data} 
                />
            </div>
    );
}

export default ContactTable;
