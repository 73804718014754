// AppContext.js

import { useLocation, useNavigate } from "react-router-dom";
import axios from "../utils/axiosConfig"
import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

// Creating a context
const ContactsContext = createContext();

// Custom hook to use the context
export const useContactsContext = () => useContext(ContactsContext);

// Provider component to wrap around components that need access to the context
export const ContactsProvider = ({ children }) => {

    const [contactNetError,setContactNetError] =useState(null);
    const [contactNetLoading, setContactNetLoading] = useState(true);
    const [allContactNetError,setAllContactNetError] =useState(null);
    const [allContactNetLoading, setAllContactNetLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    // Areas variables for regions data and loading status
    const [contacts, setContacts] = useState([]);
    const [selectedContant , setSelectedContact] = useState(null)
    // Function to fetch states in context

    const fetchContacts = async (keyword = '', page = '', count = '') => {
        try {
            // Construct the URL with query parameters
            // const url = `/contact/view?search=${keyword}&page=${page}&count=${count}`;
            const url = `/contact/view`;
            
            // Make the GET request to the constructed URL
            const response = await axios.get(url);
            
            console.log("res contact: ", response);
            
            // Check if the response status is 200
            if (response.status === 200) {
                // Log and set the contacts data
                console.log("res: ", response.data);
                setContacts(response.data);  // Assuming response.data.data is the contacts array
            // } else if (response.status === 401) {
            //     console.log("Unexpected response status 401: ", response.status);
            //     navigate('/login');
            } else {
                console.log("Unexpected response status: ", response.status);
                setAllContactNetError("حدث خطأ أثناء تحميل قائمة الأسماء، يرجى إعادة المحاولة");
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.log("Error response status 401: ", error.response.status);
                if (location.pathname !== '/login' && location.pathname !== '/register') {
                    Cookies.remove('access_token');
                    navigate('/login');
                }
            } else {
                console.error("Error fetching contacts:", error);
                setAllContactNetError("حدث خطأ أثناء تحميل قائمة الأسماء،  يرجى إعادة المحاولة");
            }
        } finally {
            setAllContactNetLoading(false);
        }
    };
    

    // Function to update states in context
    const updateContactsInContext = (newContant) => {
        setContacts(newContant);
    };
    // Function to refresh states in context
    const refreshContacts = () => {
        setAllContactNetLoading(true);
        fetchContacts();
    };
 
//AddedContact
const [addedContacts, setAddedContacts] = useState([]);
const [selectedAddedContant , setSelectedAddedContact] = useState(null)
// Function to fetch states in context
const fetchAddedContacts = async () => {
    try {
        const url = `/contact/my`;
        const response = await axios.get(url);
        console.log("res My: ", response);
        // Check if the response status is 200
        if (response.status === 200) {
            // Assuming the data you need is inside response.data.msg
            setAddedContacts(response.data.msg);
            setContactNetError(null);
        } else {
            console.log("error ", response.data.msg);
            setContactNetError("حدث خطأ أثناء تحميل قائمة الأسماء الخاصة بك، يرجى إعادة المحاولة");
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            console.log("Error response status 401: ", error.response.status);
            if (location.pathname !== '/login' && location.pathname !== '/register') {
                Cookies.remove('access_token');
                navigate('/login');
            }
        } else {
        console.error("Error fetching Added contacts:", error);
        setContactNetError("حدث خطأ أثناء تحميل قائمة الأسماء الخاصة بك، يرجى إعادة المحاولة");
    }
}finally{
        setContactNetLoading(false);
    }
};

// Function to update states in context
const updateAddedContactsInContext = (newAddedContant) => {
    setAddedContacts(newAddedContant);
};
// Function to refresh states in context
const refreshAddedContacts = () => {
    setContactNetLoading(true);
    fetchAddedContacts();
};

    useEffect(() => {
        fetchContacts();
        fetchAddedContacts();
    }, []);


    return (
        <ContactsContext.Provider
            value={{
             contacts,refreshContacts,updateContactsInContext,
             selectedContant,setSelectedContact,
             addedContacts,refreshAddedContacts,updateAddedContactsInContext,
             selectedAddedContant,setSelectedAddedContact,
             contactNetError,setContactNetError,
             contactNetLoading,
             allContactNetError,setAllContactNetError,
             allContactNetLoading
            }}
        >
            {children}
        </ContactsContext.Provider>
    );
};
