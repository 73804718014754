import React, { useState } from 'react'
import './NavigatorbarsStyles.css';
import axios from "../../utils/axiosConfig"
import Button from '../Buttons/Button';
import colors from '../Colors/color';
import { useNavigate } from 'react-router-dom';
import { useUsersContext } from '../../hooks/UsersContext';

function Upbar({ page_title, upBarContent }) {

const {countOfMessage} =useUsersContext();
  const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to the desired route when the button is clicked
    // navigate('/profile');
    navigate('/profile?selectedSection=2');
  };

  

  return (
    <>
      <nav className="nav-container">
      <h5>{page_title}</h5>
      <div className='buttons'>
      {/* <Button
                t_color={colors.white}
                b_color={colors.dark_grey}
                className="logout-btn"
                // onClick={logoutSubmit}
              >
                 {loading ? "يتم تسجيل الخروج..." : "تسجيل الخروج"} 
                remi
              </Button> */}
      <Button
        t_color={colors.white}
        b_color={colors.dark_grey}
        className="invitations_number_btn_upbar"
        // onClick={openAddPopup}
    >
     لديك {countOfMessage} دعوة
    </Button>
    <Button
    t_color={colors.light_gold}
    b_color={colors.dark_grey}
    className="new_invitation_btn_upbar"
    onClick={handleClick}
>
   شراء دعوة جديدة
</Button>
      {upBarContent}
      </div>
        </nav>
    </>
  )
}

export default Upbar
