import React, { useEffect, useState } from 'react'
import InvitationsTabbedNav from '../../components/TabbedNavigation/InvitationsTabbedNav'
import HomeLayout from '../../layouts/HomeLayout'
import TopLayout from '../../layouts/TopLayout'
import SearchBar from '../../components/SearchBar/SearchBar'
import InvitationsSlider from '../../components/Slider/InvitationsSlider'
import Button from '../../components/Buttons/Button'
import colors from '../../components/Colors/color'
import ButtomLayout from '../../layouts/ButtomLayout'
import CreateInviteSteps from '../Invitations/CreateInviteSteps'
import { usePlansContext } from '../../hooks/PlansContext'
import ProfileSlider from '../../components/Slider/ProfileSlider'
import PersonalInfo from './PersonalInfo'
import PackagesPage from './PackagesPage'
import "./ProfileStyles.css"

function ProfilePage() {
  const [selectedSection, setSelectedSection] = useState(2);


  const handleSectionSelect = (section) => {
    setSelectedSection(section);
  };

  return (
    <HomeLayout
    page_title="حسابي"
    mainContent={<>
  <ButtomLayout 
  tableClassName="buttom_full"
  children={   
    <>
    <div className="slider">
    <ProfileSlider  onSelectSection={handleSectionSelect}/>
    </div>
    <div className="create-steps">
          {/* {selectedSection && (selectedSection.id === 1) ?
        <PersonalInfo/>
      :(
        // "remi"
       <PackagesPage/>
      )
      } */}
             <PackagesPage/>
      
    </div>
     
    </>
}
  />
  {/* main */}
    </>}
    />
  )
}

export default ProfilePage
