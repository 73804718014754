import React from 'react';
import './TermsAndPoliciesStyles.css';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Buttons/Button';
import colors from '../../components/Colors/color';

const PrivacyAndPolicies = () => {
    const navigate =useNavigate();
    const handleGoBack = () =>{
        navigate("/profile")
    }
    return (
        <div className='rtl-container'>
             <div className="temrs_head">
             <h2>شروط الاستخدام والخصوصية</h2>
            <Button 
            b_color={colors.light_grey}
            t_color={colors.white}
            className="prev_tap_btn"
            onClick={handleGoBack}
            > رجوع </Button>
            </div>

        <div className="terms-container">
          
            <p>
            ברוכים הבאים לאתר COM.AZIMH (להלן: "האתר"). האתר מופעל ע"י מכון אי-ליט (ע.מ: 558353975)  (להלן: "הנהלת האתר" או "החברה").<br/>

האתר משמש כאתר הזמנות לאירועים, ואתר פרסום כרטיסי ביקור אלקטרוניים לעסקים.  <br/>
1.	כללי<br/>
<div className='section'>
1.	האמור בתקנון זה מתייחס באופן שווה לבני שני המינים, והשימוש בלשון זכר הוא מטעמי נוחות בלבד.
<br/>2.	הוראות תקנון זה ותנאי השימוש המופיעים באתר מגדירים את מערכת היחסים המשפטית בין המזמין לבין האתר, את תנאי השימוש באתר ו/או את הזמנת המוצרים או השירותים מן האתר ומעידים על הסכמת המזמין, לתנאים אלה ולתנאים נוספים המופיעים באתר.
<br/>3.	החברה רשאית בכל עת, על פי שיקול דעתה הבלעדי, לעדכן  תקנון זה.
<br/>4.	תנאים אלה חלים על השימוש באתר ובשירותים הכלולים בו באמצעות כל מחשב או מכשיר תקשורת אחר (כדוגמת טלפון סלולארי, טבלטים למיניהם וכו’) כמו כן הם חלים על השימוש באתר, בין באמצעות רשת האינטרנט ובין באמצעות כל רשת או אמצעי תקשורת אחר.
<br/>5.	אין באמור בתקנון זה כדי לגרוע מהוראות חוק הגנת הצרכן, התשמ”א-1981 (להלן: “חוק הגנת הצרכן”) והתקנות אשר הותקנו מכוחו, ככל שהן חלות על האתר (להלן: “ההוראות”), למעט במקרים בהם ניתן להתנות על הוראות כאמור והתניה כאמור בוצעה במסגרת האתר אם במפורש ואם במשתמע.
<br/>6.	החברה והנהלת האתר עושים כמיטב יכולתם להציג את המידע השלם והמקיף ביותר לגבי המוצר לרבות תמונות, חרף האמור בסעיף זה יובהר, כי עלולים להופיע באתר בתום לב וללא כוונת זדון ו/או מתוך רצון להטעות, אי דיוקים ו/או שגיאות ו/או השמטות והחברה, הנהלת האתר והאתר לא יישאו באחריות כלשהי הנובעת מאי הדיוקים ו/או או השגיאות.
<br/>7.	כותרות הפרקים מובאות לשם נוחות והתמצאות המזמין ולא ישמשו בפרשנות התקנון.
<br/>8.	טעות סופר בתיאור מוצר/ים לא תחייב את החברה.
<br/>9.	תמונות המוצרים באתר מוצגות לצורכי המחשה בלבד. בנוסף, ייתכנו הבדלים במראה, בגוון, בגודל, וכיו”ב בין המוצר, כפי שהוא מוצג באתר, לבין המוצר במציאות.
<br/>10.	אין להעתיק ולהשתמש, או לאפשר לאחרים להשתמש, בכל דרך אחרת בתכנים מתוך האתר, לרבות באתרי אינטרנט אחרים, בפרסומים אלקטרוניים, בפרסומי דפוס וכיו"ב, לכל מטרה אחרת.
<br/>11.	המועד שנרשם, לכל עניין ועניין, במחשבי החברה הוא המועד הקובע לכל דבר.
<br/>12.	נעשה שימוש באתר  mrcoral.co.il להכנת מסמך זה. 
</div>

2.	הרשמה לאתר ושימוש בשירותים 
<div className='section'>
1.	על מנת לבצע בשירותי האתר על המזמין להירשם באתר  באמצעות טופס הרשמה מקוון.
<br/>2.	כל אדם, לרבות חברה, רשאי להשתמש באתר, לרבות לבצע רכישות באמצעות האתר, בין היתר, בכפוף להיותו כשיר לבצע פעולות משפטיות מחייבות, בעל כרטיס אשראי  תקף ,שהונפק  ע"י אחת מחברות כרטיסי האשראי (לעיל ולהלן: "המזמין").
<br/>3.	הרישום הינו חד פעמי, כאשר לאחריו המזמין לא יידרש לשוב ולהירשם בכל פעם מחדש בעת ביצוע הרכישה.
<br/>4.	בעת הרישום באתר על המזמין להזין שם משתמש וסיסמא וכן את כתובת הדואר האלקטרוני שלו. במידה וישכח המזמין את הסיסמא, תשלח אליו הסיסמא הקיימת כפי שהוזנה בעת הרישום הראשוני באתר לכתובת הדוא"ל אשר הוזנה באתר.
<br/>5.	בעתיד אם וכאשר יבקש המזמין לרכוש מוצרים נוספים, יזוהה המזמין לפי שם המזמין והסיסמא שבחר.
<br/>6.	פרטי המזמין יעודכנו בהתאם לנתונים אשר ימולאו על ידי המזמין בטופס הרישום המקוון במצוי באתר.
<br/>7.	הנהלת האתר רשאית מעת לעת לדרוש פרטי זיהוי נוספים.
<br/>8.	מבלי לגרוע מהאמור לעיל, החברה תהא רשאית למנוע מכל אדם להשתמש באתר, באופן זמני או לצמיתות על פי שיקול דעתה הבלעדי ומבלי למסור הודעה על כך מראש, לרבות בכל אחד מהמקרים הבאים:

<div className='inner_section'>
1.	ביצוע מעשה בלתי חוקי ו/או הפרת הוראות הדין;
<br/>2.	הפרת תנאי מתנאי תקנון זה;
<br/>3.	מסירת פרטים שגויים במתכוון;
<br/>4.	ביצוע פעולה העלולה לפגוע בפעילותו התקינה של האתר ו/או במי מהספקים ו/או בצד ג' כלשהו;
<br/>5.	כרטיס האשראי שברשות המזמין נחסם או הוגבל לשימוש בדרך כלשהי.
</div>

9.	יתחייב המזמין בעת השימוש בשירות שליחת ההזמנות, שהשירות הוא אך ורק לשליחת הזמנות לאירועים פרטיים, ושלא ייעשה כל שימוש למטרות פרסום או למטרות עסקיות. 
<br/>10.	יתחייב המזמין להשתמש בשירות שליחת ההזמנות לאירועים באופן הוגן ותואם לתקנון, ולצרכים שהוגדרו בסעיף 9 לעיל, ושלא ישתמש בשירות באופן המהווה מטרד לצדדים שלישיים, ו\או שלא ישלח בשירות ההודעות כל תוכן מזיק או פוגעני. 
<br/>11.	עסקים המפרסמים באתר יתחייבו לפרסם על פי המקובל בפרסומות המופנים לציבור הרחב, ושלא ייכלל בתוכן שהם מזינים לתוך האזור האישי שלהם כל תוכן פוגעני.
<br/>12.	חשוב לציין כי שירות הנרכשים באתר, בין אם שליחת הזמנות לאירועים ובין אם קניית שטח פרסום למטרת פרסום, לא ימומשו ויצאו לפועל אלא לאחר אישורם על ידי הנהלת האתר, ולא תהיה למזמינים כל טענה נגד החלטת הנהלת האתר לביטול ההזמנה, ועניין אישור הרכישות יהיה לשיקול דעתה הבלעדי של הנהלת האתר.
<br/>13.	בעניין רכישת חבילות ההודעות המיועדות לשירות שליחת ההזמנות לאירועים, לא תהיה למזמין כל זכות לבקש זיכוי במידה וחבילת ההודעות שנרכשה לא נוצלה במלואה. 
<br/>14.	אין החברה אחראית על אי הגעת ההודעות למספרים הנמענים, מסיבות הלא קשורות לתקלות טכניות בהפעלת השירות באתר. 
<br/>15.	יובהר כי חבילת ההודעות שנקנתה תעמוד לרשות המזמין לחודש ימים בלבד, ולאחר מכן תוקף החבילה יפוג, ודינה יהיה כדין חבילה שנוצלה במלואה. 
<br/>16.	קניית שטחי פרסום לפי המתווים המופיעים באתר תהיה סופית, ולא יהיה ניתן לבטל את הפרסום לפני מועד סיומו על פי המתווה שנרכש. 
<br/>17.	אנשים שהוסיפו את מספריהם בקישור הוספת המספרים לרשימות המוזמנים, יהיו זכאים לבקש מחיקת שמם מהרשימות דרך שליחת בקשה לכך לדוא"ל azimh.info@gmail.com, והשם יימחק מהרשימות תוך 14 יום.     
</div>
3.	רכישת שירותים באתר
<div className='section'>
1.	רכישת השירותים תתבצע באמצעות רכישת חבילות הזמנות המופיעים באתר ו\או קניית שטח לפרסום כרטיס ביקור אלקטרוני של המפרסם, לאחר מכן יזין המזמין בטופס המקוון המיועד לכך בתהליך ההזמנה את פרטי המזמין הבאים: שם פרטי, שם משפחה, מס' טלפון, כתובת דואר אלקטרוני. השדות המסומנים בכוכבית קיימת חובה למלאם ובלעדיהם לא תתאפשר השלמת ההזמנה.
<br/>2.	על מנת למנוע כל אפשרות של תקלה בקבלת השירות על המזמין למסור רק פרטים מדויקים ונכונים.
<br/>3.	בעת פעולת השלמת ההזמנה יאשר המזמין את פרטי ההזמנה ואת נכונות הפרטים שמסר.
<br/>4.	מילוי כל הפרטים מהווה תנאי מוקדם לביצוע ההזמנה וזאת לשם ביצוע ההזמנה ביעילות וללא תקלות ועל כן יש להקפיד על מסירת כל הפרטים במדויק.
<br/>5.	פרטי המזמין יעודכנו בהתאם לאמור בטופס ההזמנה המקוון באתר.
<br/>6.	מילוי כל הפרטים הנדרשים לרכישת השירות על ידי המזמין יחשב לביצוע הזמנה (להלן: "ההזמנה") עם קבלת ההזמנה, האתר יבצע בדיקה של פרטי כרטיס האשראי ורק לאחר אישור חברת האשראי ו/או לאחר קבלת אישור מפיי פאל כפי שיובהר להלן, הפעולה תאושר ויצא אישור סופי של ההזמנה, חיוב הלקוח עבור השירות הנרכש יבוצע דרך כרטיס האשראי ו/או דרך חשבון הפיי פאל כפי שיבואר להלן והכל בכפוף ליכולת האתר לספק את השירות הנדרש.
<br/>7.	הפרטים כפי שהוזנו בטופס ההזמנה על ידי המזמין, יהוו ראיה חלוטה לנכונות הפעולות.
</div>
4.	אופן ביצוע התשלום עבור ההזמנה
<div className='section'>
1.	התשלום עבור המוצרים יעשה באמצעות כרטיס אשראי או באמצעות חשבון בשירות "ארנק אלקטרוני", וכל שירות דומה, כפי שיהיה זמין לשימוש באתר, מעת לעת לפי שיקול דעתה של הנהלת האתר.
<br/>2.	אם יבחר המזמין להשתמש בכרטיס אשראי לשם ביצוע התשלום, יתבקש המזמין למסור את פרטי כרטיס האשראי, תעודת זהות, סוג הכרטיס ותוקפו. אם החליט המזמין לשלם באמצעות הפיי פאל, החברה תוכל לגבות את התשלום עבור המוצרים רק לאחר קבלת אישור מפיי פאל. השימוש וקבלת האישור מפיי-פאל כפופים לתנאי השימוש של אתר פיי פאל.
<br/>3.	הנהלת האתר שומרת לעצמה את הזכות להפסיק את השימוש באמצעי התשלום כלשהו באתר, להתיר שימוש באמצעי תשלום נוספים ולהחיל הסדרי תשלום שונים על סוגי כרטיסי אשראי שונים או אמצעי תשלום שהנהלת האתר תכבד.
<br/>4.	לאחר שהוזנו פרטי התשלום בטופס ההזמנה המקוון, ישלח אל המזמין אישור באמצעות הדואר האלקטרוני על קליטת פרטי ההזמנה.
<br/>5.	מיד לאחר ביצוע ההזמנה, תבצע הנהלת האתר בדיקה של אמצעי התשלום בו השתמש המזמין, תינתן הודעה מתאימה למזמין כי ההזמנה אושרה וחשבונו יחויב בעלות השירות.
<br/>6.	במקרה בו לא אושרה  העסקה על ידי חברת האשראי או על ידי חשבון הפיי פאל או כל אמצעי תשלום אר שיהיה באותה עת, יקבל המזמין הודעה מתאימה על כך.
<br/>7.	יובהר כי יתכנו מצבים בהם על אף שהשירות מוצג באתר כקיים, בפועל הוא מושבת ולא ניתן לספק אותו, במקרים אלו תבוטל העסקה ולמזמין לא תהה כל טענה בקשר לכך בכפוף להשבת הסכום ששולם על ידי הלקוח.
<br/>8.	במידה והמזמין חויב בטעות על ידי חברת האשראי, על המזמין להודיע להנהלת האתר על מנת לבצע זיכוי בהתאם.
</div>
5.	ביטול רכישה מצד המזמין
<div className='section'>
    <div className='inner_section'>
1.	המזמין רשאי לבטל את העסקה בהתאם להוראות חוק הגנת הצרכן והתקנות שהותקנו על פיו. מבלי לגרוע מהאמור:
<div className='inner_section_2'>
1.	ביטול ייעשה אך ורק בהודעה בכתב לכתובת הדוא"ל azimh.info@gmail.com.
<br/>2.	ביטול עסקה ע"י המזמין לא יתאפשר ברכישת פריטים מסוימים כמפורט בסעיף קטן 14ג(ד) לחוק הגנת הצרכן.
<br/>3.	לאחר קבלת הודעת הביטול יושב למזמין הסכום ששולם על ידו בגין המוצר בניכוי דמי ביטול בשיעור של 5% מסכום העסקה או 100 ש"ח, לפי הנמוך. במקרה של ביטול עסקה עקב פגם או אי התאמה, לא יחויב המזמין בדמי ביטול כלשהם.
</div>
</div>
</div>
6.	ביטול רכישה מצד החברה
<div className='section'>
1.	החברה תהא רשאית, על פי שיקול דעתה הבלעדי, בשל כל סיבה, ובכל עת, לבטל או להפסיק עסקה ו/או מכירה ו/או לבטל הזמנה, כולה או חלקה, ו/או את פעילות האתר, כולו או חלקו.
<br/>2.	הודעה על ביטול או הפסקה מעין זה תימסר למשתמש או למזמין, והחברה תימנע מחיוב כרטיס האשראי של המזמין או תשיב לו כל סכום ששולם בגין המוצרים, ככל ששולם.
<br/>3.	למעט בגין השבת סכום העסקה כאמור, לא תהיה למשתמש או למזמין כל טענה, תביעה ו/או דרישה כלפי החברה ו/או הספק בגין ביטול העסקה כאמור בסעיף זה.
</div>

7.	 זכויות יוצרים
<div className='section'>
1.	כל זכויות הקניין הרוחני, לרבות פטנטים, זכויות היוצרים, הדגמים, המדגמים והסודות המסחריים, ורשימות השמות המופיעים באתר ההזמנות הנם רכושם של החברה בלבד או של צדדים שלישיים אחרים שהרשו להנהלת האתר להשתמש בהם.
<br/>2.	זכויות אלה חלות בין השאר על הנתונים באתר, לרבות רשימת המוצרים, תאור ועיצוב המוצרים וכל פרט אחר הקשור להפעלתו.
<br/>3.	זכויות אלה חלות גם על שם האתר ושם המתחם (www.azimh.com ) של האתר, סימני המסחר (בין אם נרשמו ובין אם לא) הם כולם רכושה של החברה. אין לעשות בהם שימוש בלא קבלת הסכמתה בכתב ומראש.
<br/>4.	אין להעתיק, לשכפל, להפיץ, למכור, לשווק, להשכיר ולתרגם מידע כלשהו מהאתר, לרבות סימני מסחר, תמונות וטקסטים, עיצוב המוצרים תמונות המוצרים וכיו"ב בלא קבלת אישור מראש ובכתב של החברה.
<br/>5.	אין לקשר לתכנים מהאתר, שאינם דף הבית של האתר (קישור עמוק) ואין להציג או לפרסם תכנים כאמור בכל דרך שהיא, אלא אם הקישור העמוק יהיה לדף האינטרנט באתר במלוא וכפי שהוא, כך שניתן יהיה לצפות ולהשתמש בו באופן הזהה לחלוטין לשימוש ולצפייה בו באתר  וזאת תוך קבלת ההסכמה מהנהלת האתר.
<br/>6.	הנהלת האתר רשאית להורות על ביטול של קישור עמוק גם לאחר מתן הסכמתה וזאת לפי שיקול דעתה הבלעדי ובמקרב זה לא תהיה לך כל טענה ו/או דרישה ו/או תביעה כלפי הנהלת האתר.
</div>
6.	אחריות
<div className='section'>
1.	החברה (ו/או מי מטעמה) אינה נושאת במישרין ו/או בעקיפין באחריות כלשהי לנזקים הנובעים ו/או הקשורים בכל דרך שהיא, לשליחת מידע מזיק בתוכן הודעות ההזמנות, או לשליחת הזמנות למספרים שהמזמין הוסיף בעצמו לרשימת המוזמנים.
<br/>2.	החברה (ו/או מי מטעמה) אינה נושאת במישרין או בעקיפין באחריות כלשהי לנזקים הנובעים כתוצאה משימוש ו/או הסתמכות על מידע המתפרסם באתרים חיצוניים, שאליהם ניתן להגיע באמצעות איזה מהשירותים באתר. יובהר כי החברה עושה ותעשה כמיטב יכולתה לשתף פעולה עם ספקים אמינים ובעלי מוניטין בלבד.
<br/>3.	החברה (ו/או מי מטעמה) אינה נושאת במישרין ו/או בעקיפין באחריות כלשהי לנזקים הנובעים ו/או הקשורים בכל דרך שהיא בשימוש ו/או בביצועי האתר.
<br/>4.	בכל מקרה החברה לא תישא באחריות לפעילות כלשהי של כל גורם אחר שאינו בשליטתה המלאה.
</div>

10.	סודיות ופרטיות
<div className='section'>
1.	כל פרטיו האישים של המזמין (שם, דואר אלקטרוני וכדומה), יישמרו במאגרי המידע של החברה.
<br/>2.	החברה לא תעביר פרטיו האישיים של המזמין לאף גורם אחר שלא בהסכמתו.
<br/>3.	החברה לא תעשה כל שימוש בפרטים של אמצעי התשלום של המזמין אלא לביצוע תשלום בגין עסקה אותה ביקש המזמין לערוך, ופרטים אלו לא יועברו לאף גורם אחר זולת לצורך זה. למען הסר ספק, פרטי אמצעי התשלום אינם נשמרים במאגרי המידע של החברה.
<br/>4.	למרות האמור לעיל, החברה תהא רשאית להעביר פרטיו האישיים של משתמש לצד שלישי במקרים בהם המזמין ביצע מעשה או מחדל הפוגעים ו/או העלולים לפגוע בחברה ו/או בצדדים שלישיים כלשהם, המזמין עשה שימוש בשירותי החברה לביצוע מעשה בלתי חוקי, או מנוגד לתקנון, אם התקבל בידי החברה צו שיפוטי המורה לה למסור את פרטי המזמין לצד שלישי וכן בכל מחלוקת או הליכים משפטיים.
<br/>5.	החברה תוכל להשתמש בפרטיו האישיים של המזמין, ללא זיהוי המזמין הספציפי, לצורך ניתוח מידע סטטיסטי והצגתו ו/או מסירתו לגורמים אחרים.
<br/>6.	מאחר שמדובר בביצוע פעולות בסביבה מקוונת אין באפשרותה של החברה להבטיח חסינות מוחלטת מפני חדירות למחשביה או חשיפת המידע האגור בידי מבצעי פעולות בלתי חוקיות. אם יעלה בידי צד שלישי לחדור למידע אשר שמור בידי החברה ו/או להשתמש בו לרעה, לא תהיה למשתמש כל טענה, תביעה או דרישה כלפי החברה.
<br/>7.	החברה תהא רשאית לעשות שימוש ב – "עוגיות" ("cookies") על מנת לספק למשתמש שירות מהיר ויעיל ולחסוך מהמזמין הצורך להזין את פרטיו האישיים בכל כניסה לאתר.
<br/>8.	בקיום מקרים שאינם בשליטתה של החברה ו/או הנובעים מכח עליון, החברה לא תהא אחראית לכל נזק מכל סוג שהוא, עקיף או ישיר, שייגרם למזמין ו/או למי מטעם המזמין עם מידע זה יאבד או אם יעשה בו שימוש לא מורשה.
</div>
11.	דין ושיפוט
<div className='section'>
1.	הדין החל על תקנון זה ו/או על כל פעולה ו/או על סכסוך הנובע ממנו, הוא הדין הישראלי בלבד.
<br/>2.	בכל מקרה של מחלוקת, תהא לבתי המשפט השלום בכפר מסעדה, או בית משפט מחוזי בנצרת הסמכות הבלעדית לדון בה.
<br/>3.	הדין החל על השימוש באתר, על ההזמנה ועל תקנון זה, לרבות פרשנותו ואכיפתו של התקנון הוא הדין הישראלי בלבד.
</div>

  
              
            </p>
        </div>
        </div>
    );
};

export default PrivacyAndPolicies;
