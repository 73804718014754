import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MyModal from '../../components/Modal/MyModal';
import Form from '../../components/Forms/Form';
import axios from '../../utils/axiosConfig';
import { useRegionsContext } from '../../hooks/RegionsContext';
import CustomAlert from "../../components/Alert/CustomAlert"

function AddContact({ onClose }) {
  const {areas}  = useRegionsContext();
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fields = [
    { label: 'First Name', type: 'text', name: 'firstname' },
    { label: 'Father Name', type: 'text', name: 'father_name' },
    { label: 'Last Name', type: 'text', name: 'lastname' },
    { label: 'Phone Number', type: 'text', name: 'phone' },
    // { label: 'Area', type: 'text', name: 'areas_id' },
    { label: 'Area', type: 'select', name: 'areas_id', options: areas.map(area => ({ value: area.area.id, label: area.area.ar_name })) },
  ];

  const createContact = async (formData) => {
    try {
      setLoading(true);
      const response = await axios.post('/contacts', formData); // Adjust the API endpoint as needed
      console.log("res", response.data); // Handle the response data as needed
      // Redirect user to another page upon successful form submission
      navigate("/contacts");
      onClose(); // Close the modal after successful form submission
    } catch (error) {
      console.error('Error occurred during form submission:', error);
      setError(error.response?.data?.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };
  if (!areas.length) {
    // Render loading state or handle when areas is empty
    return <div>Loading...</div>;
  }

  const handleErrorClose = () => {
    setError(null);
  };

  return (
    <div className='modal-categories'>
      <MyModal
        title="Add Contact"
        show={true} // Always keep the modal open
        onHide={onClose}
        // modal_className="add_Contact"
        body={
          <>
            {/* {error && <div className="error-message">{error}</div>} */}
            <Form
              form_className="contact-form-container"
              form_title="Add Contact"
              fields={fields}
              formRef={formRef}
              onSubmit={createContact}
              btn_text={loading ? "Adding..." : "Add Contact"}
              disabled={loading}
            />
            {error !== null && <CustomAlert message={error}  onClose={handleErrorClose} />}  
          </>
        }
      />
    </div>
  );
}

export default AddContact;
