// AppContext.js

import { useLocation, useNavigate } from "react-router-dom";
import axios from "../utils/axiosConfig"
import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

// Creating a context
const PlansContext = createContext();

// Custom hook to use the context
export const usePlansContext = () => useContext(PlansContext);

// Provider component to wrap around components that need access to the context
export const PlanProvider = ({ children }) => {

    const navigate =useNavigate();
    const location = useLocation();

    const [netError,setNetError] =useState(null);
    const [netLoading, setNetLoading] = useState(true);

    const [mySentNetError,setMySentNetError] =useState(null);
    const [mySentNetLoading, setMySentNetLoading] = useState(true);


    // Areas variables for regions data and loading status
    const [plans, setPlans] = useState([]);
    const [selectedPlan , setSelectedPlan] = useState(null);
    const [selectedInvitation , setSelectedInvitation] = useState(null)
    const [selectedSentInvitation , setSelectedSentInvitation] = useState(null)
    
    // Function to fetch states in context
    const fetchPlan = async () => {
        try {
            const response = await axios.get('/plan/view');
            // console.log("res plan: ", response);
            if (response.status === 200 && response.data.success === 1) {
                const posts = response.data.posts;
                if (posts && posts.original) {
                    // console.log("resplan2 : ", posts.original);
                    try {
                        setPlans(posts.original);
                        setNetError(null);
                    } catch (setPlanError) {
                        console.error("Error setting plans:", setPlanError);
                        setNetError("حدث خطأ أثناء تحميل الباقات. يرجى المحاولة مرة أخرى.");                    }
                } else {
                    console.error("Unexpected response structure:", response.data);
                    setNetError("حدث خطأ أثناء تحميل الباقات. يرجى المحاولة مرة أخرى.");
                }
            } else {
                console.log("error ", response.data);
                setNetError("حدث خطأ أثناء تحميل الباقات. يرجى المحاولة مرة أخرى.");
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.log("Error response status 401: ", error.response.status);
                if (location.pathname !== '/login' && location.pathname !== '/register') {
                    Cookies.remove('access_token');
                    navigate('/login');
                }
            } else {
            console.error("Error fetching plans:", error);
            setNetError("حدث خطأ أثناء تحميل الباقات. يرجى المحاولة مرة أخرى.");
            }
        }finally{
            setNetLoading(false)
        }
    };
     

    // Function to update states in context
    const updatePlansInContext = (newPlan) => {
        setPlans(newPlan);
    };
    // Function to refresh states in context
    const refreshPlans = () => {
        setNetLoading(true);
        fetchPlan();
    };

   
 
    const [mySentInvitation, setMySentInvitation] = useState([]);
    const fetchSentInvitation = async () => {
        try {
            const response = await axios.get('/invitation/view');
            // console.log("res invitation: ", response);
    
            // Assuming response.data is an array
            if (Array.isArray(response.data) && response.status === 200) {
                // Log the data and set the state with the fetched data
                console.log("Invitations received:", response.data);
                setMySentInvitation(response.data);
                setMySentNetError(null);
            } else {
                console.log("Unexpected response structure", response.data);
                setMySentNetError("حدث خطأ أثناء تحميل الدعوات المرسلة. يرجى المحاولة مرة أخرى.");
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.log("Error response status 401: ", error.response.status);
                if (location.pathname !== '/login' && location.pathname !== '/register') {
                    Cookies.remove('access_token');
                    navigate('/login');
                }
            } else {
            console.error("Error fetching invitations:", error);
            setMySentNetError("حدث خطأ أثناء تحميل الدعوات المرسلة. يرجى المحاولة مرة أخرى.");
            }
        }finally{
            setMySentNetLoading(false)
        }
    };
    

     // Function to update states in context
     const updateSentInvitationInContext = (newSentInvitation) => {
        setMySentInvitation(newSentInvitation);
    };
    // Function to refresh states in context
    const refreshSentInvitation = () => {
        setMySentNetLoading(false);
        fetchSentInvitation();
    };

    useEffect (()=>{
        fetchSentInvitation();
        fetchPlan();
    },[])

    return (
        <PlansContext.Provider
            value={{
             plans,refreshPlans,updatePlansInContext,
             selectedPlan,setSelectedPlan,
             selectedInvitation , setSelectedInvitation,
             mySentInvitation,refreshSentInvitation,updateSentInvitationInContext,
             selectedSentInvitation , setSelectedSentInvitation,
             netError,setNetError,
             netLoading,
             mySentNetError, setMySentNetError,
             mySentNetLoading
            }}
        >
            {children}
        </PlansContext.Provider>
    );
};
