const colors = {
    gold: '#AB9354', // Golden
    light_gold: '#DBB658', // Light gold
    extra_light_gold: '#F1E6C7', // Extra Light gold
    success: '#237763', // Green
    rejected: '#E33629', // Red
    white:  '#FFFFFF' ,//white
    dark_grey: '#404040',
    grey:"#908D8D",
    light_grey :'#B2B2B2',
    tab_gold :"#8E7232",
    black: "#000000"
  };
  
  export default colors;
  