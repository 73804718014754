import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MyModal from '../../components/Popup/MyModal';
import Form from '../../components/Forms/Form';
import axios from '../../utils/axiosConfig';

function AddArea({ onClose }) {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fields = [
    { label: 'Arabic Name', type: 'text', name: 'ar_name' },
    { label: 'English Name', type: 'text', name: 'en_name' },
    { label: 'Hebrew Name', type: 'text', name: 'he_name' },
  ];

  const createArea = async (formData) => {
    try {
      setLoading(true);
      const response = await axios.post('/areas', formData); // Adjust the API endpoint as needed
      console.log("res", response.data); // Handle the response data as needed
      // Redirect user to another page upon successful form submission
      navigate("/areas");
      onClose(); // Close the modal after successful form submission
    } catch (error) {
      console.error('Error occurred during form submission:', error);
      setError(error.response?.data?.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='modal-categories'>
      <MyModal
        title="Add Area"
        show={true} // Always keep the modal open
        onHide={onClose}
        body={
          <>
            {error && <div className="error-message">{error}</div>}
            <Form
              form_className="contact-form-container"
              form_title="Add Area"
              fields={fields}
              formRef={formRef}
              onSubmit={createArea}
              btn_text={loading ? "Adding..." : "Add Area"}
              disabled={loading}
            />
          </>
        }
      />
    </div>
  );
}

export default AddArea;
