import React, { Children } from 'react';
import "./ButtonStyles.css"

const Button = ({ onClick, children ,btn_type,t_color ,b_color ,className,disabled_condition}) => {
  return (
    <button type={btn_type} style={{backgroundColor:b_color, color: t_color}}
            className={className}
            onClick={onClick}
            disabled={disabled_condition}
            >
      {children}
    </button>
  );
};

export default Button;
