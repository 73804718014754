// import React from 'react';
// import './CustomAlert.css';

// const CustomAlert = ({ message }) => {
//   return (
//     <div className="custom-alert">
//       <p>{message}</p>
//     </div>
//   );
// };

// export default CustomAlert;

import React from 'react';
import './CustomAlert.css';
import Button from '../Buttons/Button';
import colors from '../Colors/color';

const CustomAlert = ({ message, onClose ,btnName ,children}) => {
  return (
    <div className="custom-alert">
      <div className="alert-content">
        <p>{message}</p>
        {children}
        <Button
        className="close-alert-btn"
        onClick={onClose}
        b_color={colors.light_gold}
        t_color={colors.white}
        >
           {btnName ? btnName : "إغلاق "}  
        </Button>
        {/* <button className="close-btn" onClick={onClose}>Close</button> */}
      </div>
    </div>
  );
};

export default CustomAlert;
