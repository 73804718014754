import React, { useState } from 'react'
import AuthLayout from '../../layouts/AuthLayout'
import axiosConfig from "../../utils/axiosConfig"
import { FaUser } from "react-icons/fa6";
import { FaLock } from "react-icons/fa";
import '../Login/LoginFrom.css'
import Button from '../../components/Buttons/Button';
import colors from '../../components/Colors/color';
import Form from '../../components/Forms/Form';
import { useNavigate } from 'react-router-dom';
import CustomAlert from '../../components/Alert/CustomAlert';
import { useUsersContext } from '../../hooks/UsersContext';

function SignupForm() {

  const navigate = useNavigate();
  const {setAuthenticatedMsg} = useUsersContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const handleErrorClose = () => {
    setError(null);
  };

  const fields = [
    { placeholder:' ادخل الاسم ', type: 'text', name: 'name' ,className:'login_input' },
    { placeholder:' ادخل الايميل  ', type: 'text', name: 'email' ,className:'login_input'},
    { placeholder:' ادخل كلمة السر ', type: 'password', name: 'password' ,className:'login_input'},
    { placeholder:' تأكيد كلمة السر ', type: 'password', name: 'password_confirmation' ,className:'login_input'},
  ];
  const formLinks = [
    { nav: '/login', text: ' لديك حساب؟' },
    // { nav: 'https://api.azimh.com/api/redirect/facebook',target : " _blank", text: 'تسجيل الدخول باستخدام Facebook' },
    // { nav: 'https://api.azimh.com/api/redirect/google',target : " _blank", text: 'تسجيل الدخول باستخدام Gmial' },
  ];

  const loginSubmit = async (formData) => {
    console.log("form " ,formData)
    try {
      setLoading(true);
      const response = await axiosConfig.post('/add', formData); // Adjust the API endpoint as needed
      console.log("response Sin up: ",response);

      console.log(response.data); // Handle the response data as needed
      setAuthenticatedMsg(response.data.msg);
      setError(null)
      navigate("/");
    } catch (error) {
      console.error('Error occurred during SignUp:', error);
      setError('Error occurred during Signup')
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <Form
  form_className="signup-form-container"
  form_title="إنشاء حساب"
   fields={fields}
   showLink={true}
   links={formLinks}
   onSubmit={loginSubmit} // Pass the handleSubmit function as onSubmit prop
   btn_className='login-btn'
   btn_text={loading ? "يتم إنشاء حساب..." : "إنشاء حساب"}
  disabled={loading}
  />
    {error !== null && <CustomAlert message={error}  onClose={handleErrorClose} />}
    </>

  )
}

export default SignupForm
