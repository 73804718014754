// AppContext.js

import { useLocation, useNavigate } from "react-router-dom";
import axios from "../utils/axiosConfig"
import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

// Creating a context
const RegionsContext = createContext();

// Custom hook to use the context
export const useRegionsContext = () => useContext(RegionsContext);

// Provider component to wrap around components that need access to the context
export const RegionsProvider = ({ children }) => {
     
    const navigate = useNavigate();
    const location = useLocation();

    const [netError,setNetError] =useState(null);
    const [netLoading, setNetLoading] = useState(true);

    // Areas variables for regions data and loading status
    const [areas, setAreas] = useState([]);
    const [selectedArea , setSelectedArea] = useState(null)
    // Function to fetch states in context
    const fetchAreas = async () => {
        try {
            const response = await axios.get(`/area/view`);
            // console.log("res Areas: ", response);
            if (response.status === 200) {
                // console.log("res: ", response.data.posts.original);
                setAreas(response.data.posts.original);
                setNetError(null);
            } else {
                console.log("error ", response);
                setNetError("حدث خطأ أثناء تحميل المناطق . يرجى المحاولة مرة أخرى.");
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.log("Error response status 401: ", error.response.status);
                if (location.pathname !== '/login' && location.pathname !== '/register') {
                    Cookies.remove('access_token');
                    navigate('/login');
                }
            } else {
            console.error("Error fetching areas:", error);
            setNetError("حدث خطأ أثناء تحميل المناطق . يرجى المحاولة مرة أخرى.");
            }
        }finally{
            setNetLoading(false)
        }
    };
    
    // Function to update states in context
    const updateAreasInContext = (newArea) => {
        setAreas(newArea);
    };
    // Function to refresh states in context
    const refreshAreas = () => {
        setNetLoading(true);
        fetchAreas();
    };
 

    useEffect(() => {
        fetchAreas();
    }, []);


    return (
        <RegionsContext.Provider
            value={{
             areas,refreshAreas,updateAreasInContext,
             selectedArea,setSelectedArea,
             netError,setNetError,
             netLoading
            }}
        >
            {children}
        </RegionsContext.Provider>
    );
};
