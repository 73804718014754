
import InvititionsSentIcon from "../../assets/icons/InvitationsSentIcon";
import InvititionsIcon from "../../assets/icons/InvititionsIcon";
import colors from "../Colors/color";
import Asset1 from "../../assets/SVG/Asset 1.svg"
import Asset2 from "../../assets/SVG/Asset 2.svg"
import Asset3 from "../../assets/SVG/Asset 3.svg"
import Asset4 from "../../assets/SVG/Asset 4.svg"
import Asset1Colored from "../../assets/ColoredIcons/Asset 1Colored.svg";
import Asset2Colored from "../../assets/ColoredIcons/Asset 2Colored.svg";
import Asset3Colored from "../../assets/ColoredIcons/Asset 3Colored.svg";
import Asset4Colored from "../../assets/ColoredIcons/Asset 4Colored.svg";
import "./NavigatorbarsStyles.css"

export const MenuItems = [
    {
      title: "حسابي",
      url: "/profile",
      // url: "/contacts",
      cName: "nav-links",
      icon : Asset1,
      selectedIcon: Asset1Colored
    },
    {
      title: "جهات الاتصال المضافة",
      url: "/contacts_added",
      // url: "/contacts",
      cName: "nav-links",
      icon: Asset2,
      selectedIcon: Asset2Colored
    },
    {
      title: "إدارة الدعوات",
      url: "/invitations",
      cName: "nav-links",
      icon: Asset3,
      selectedIcon: Asset3Colored
    },
    {
      title: "الدعوات المرسلة",
      url: "/sent_invitations",
      // url: "/contacts",
      cName: "nav-links",
      icon: Asset4,
      selectedIcon: Asset4Colored
    },
];
  