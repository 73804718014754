// import React, { useEffect, useState } from 'react'
// import InvitationsTabbedNav from '../../components/TabbedNavigation/InvitationsTabbedNav'
// import HomeLayout from '../../layouts/HomeLayout'
// import TopLayout from '../../layouts/TopLayout'
// import SearchBar from '../../components/SearchBar/SearchBar'
// import InvitationsSlider from '../../components/Slider/InvitationsSlider'
// import Button from '../../components/Buttons/Button'
// import colors from '../../components/Colors/color'
// import ButtomLayout from '../../layouts/ButtomLayout'
// import CreateInviteSteps from './CreateInviteSteps'
// import { usePlansContext } from '../../hooks/PlansContext'
// import CustomAlert from '../../components/Alert/CustomAlert'
// import { useUsersContext } from '../../hooks/UsersContext'
// import { useNavigate } from 'react-router-dom'

// function InvitationsPage() {
//   const {selectedInvitation} = usePlansContext();
//   const {countOfMessage} =useUsersContext();
//   const [invitationsArray,setInvitationsArray]=useState([]);
//   const [iterator,setIterator]=useState(1);
//   const [index,setIndex]=useState(1);
//   const [showAlert, setShowAlert] = useState(null);
//   const [buyPlanshowAlert, setBuyPlanShowAlert] = useState(null);
//   const navigate = useNavigate();

//   const handleCloseAlert = () => {
//     setShowAlert(null);
//   };
//   const handleCloseBuyPlanAlert = () => {
//     setBuyPlanShowAlert(null);
//   };
//   const handleBuyPlanAlert = () => {
//     setBuyPlanShowAlert(null);
//     navigate('/profile');
//   };

//     // Load invitationsArray from localStorage when the component mounts
//     useEffect(() => {
//       const storedInvitations = localStorage.getItem('invitationsArray');
//       if (storedInvitations) {
//         setInvitationsArray(JSON.parse(storedInvitations));
//       }
//         const storedIterator = localStorage.getItem('iterator');
//     if (storedIterator) {
//       setIterator(parseInt(storedIterator));
//     }
//     }, []);
  
//     // Save invitationsArray to localStorage whenever it changes
//     useEffect(() => {
//       localStorage.setItem('invitationsArray', JSON.stringify(invitationsArray));
//     }, [invitationsArray]);
//     useEffect(() => {
//       localStorage.setItem('iterator', iterator.toString());
//     }, [iterator]);

//   const createInvitation = () => {
//     if (countOfMessage == 0) {
//       setBuyPlanShowAlert("لا يمكنك إنشاء دعوة جديدة دون شراء باقة");
//       return;
//     }
//     if (invitationsArray.length >= 4) {
//       // setShowAlert(true);
//       setShowAlert("لا يمكنك إنشاء أكثر من أربع دعوات في نفس الوقت");
//       return;
//     }
//     setInvitationsArray([...invitationsArray, { id: index, text: `الدعوة ${index}` }]);
//     setIterator(iterator + 1);
//     setIndex(index +1 );
//   };

//   const removeInvitation = () => {
//     setInvitationsArray(invitationsArray.filter(invitation => invitation.id !== selectedInvitation.id));
//     setIterator(iterator - 1);

//     const updatedInvitationsArray = invitationsArray.filter(invitation => invitation.id !== selectedInvitation.id);
//     localStorage.setItem('invitationsArray', JSON.stringify(updatedInvitationsArray));
//     localStorage.setItem('iterator', (iterator - 1).toString());
//   };

//   const upBarContent = (
//     <Button
//         // t_color={colors.white}
//         b_color={colors.dark_grey}
//         className={iterator >= 5 ? "start_new_invitation disabled" : "start_new_invitation"}
//         onClick={createInvitation}
//         // disabled_condition={iterator >= 5}
//     >
//       <span>+</span>إنشاء دعوة 
//     </Button>
// );
//   return (
//     <>
//      <HomeLayout
//     page_title="إرسال دعوة"
//      upBarContent={upBarContent}
//     mainContent={<>
//   <ButtomLayout 
//   tableClassName="buttom_full"
//   children={   
//     <>
//     <div  className="slider">
//     <InvitationsSlider invitations_array={invitationsArray}/>
//     </div>
//      <div className="create-steps">
//      {selectedInvitation &&  <CreateInviteSteps  onSentSuccess={removeInvitation} />}

//      </div>
     
//     </>
// }

//   />
  
//     </>}
//     />
//          {showAlert !== null && <CustomAlert message={showAlert} onClose={handleCloseAlert}/>}
//          {buyPlanshowAlert !== null && <CustomAlert message={buyPlanshowAlert}
//           children = {<div>
//             <Button
//             onClick={handleBuyPlanAlert}
//             className="close-alert-btn"
//             b_color={colors.light_gold}
//             t_color={colors.white}
//             >
//               شراء باقة
//             </Button>
//             </div>}
//           onClose={handleCloseBuyPlanAlert}/>}

//     </>
   
//   )
// }

// export default InvitationsPage
import React, { useEffect, useState } from 'react';
import InvitationsTabbedNav from '../../components/TabbedNavigation/InvitationsTabbedNav';
import HomeLayout from '../../layouts/HomeLayout';
import TopLayout from '../../layouts/TopLayout';
import SearchBar from '../../components/SearchBar/SearchBar';
import InvitationsSlider from '../../components/Slider/InvitationsSlider';
import Button from '../../components/Buttons/Button';
import colors from '../../components/Colors/color';
import ButtomLayout from '../../layouts/ButtomLayout';
import CreateInviteSteps from './CreateInviteSteps';
import { usePlansContext } from '../../hooks/PlansContext';
import CustomAlert from '../../components/Alert/CustomAlert';
import { useUsersContext } from '../../hooks/UsersContext';
import { useNavigate } from 'react-router-dom';

function InvitationsPage() {
  const { selectedInvitation } = usePlansContext();
  const { countOfMessage } = useUsersContext();
  const [invitationsArray, setInvitationsArray] = useState([]);
  const [iterator, setIterator] = useState(1);
  const [showAlert, setShowAlert] = useState(null);
  const [buyPlanshowAlert, setBuyPlanShowAlert] = useState(null);
  const navigate = useNavigate();

  const handleCloseAlert = () => {
    setShowAlert(null);
  };

  const handleCloseBuyPlanAlert = () => {
    setBuyPlanShowAlert(null);
  };

  const handleBuyPlanAlert = () => {
    setBuyPlanShowAlert(null);
    navigate('/profile');
  };

  // Load invitationsArray and iterator from localStorage when the component mounts
  useEffect(() => {
    const storedInvitations = localStorage.getItem('invitationsArray');
    if (storedInvitations) {
      setInvitationsArray(JSON.parse(storedInvitations));
    }
    const storedIterator = localStorage.getItem('iterator');
    if (storedIterator) {
      setIterator(parseInt(storedIterator));
    }
  }, []);

  // Save invitationsArray to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('invitationsArray', JSON.stringify(invitationsArray));
  }, [invitationsArray]);

  // Save iterator to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('iterator', iterator.toString());
  }, [iterator]);

  const createInvitation = () => {
    if (countOfMessage === 0) {
      setBuyPlanShowAlert("لا يمكنك إنشاء دعوة جديدة دون شراء باقة");
      return;
    }
    if (invitationsArray.length >= 4) {
      setShowAlert("لا يمكنك إنشاء أكثر من أربع دعوات في نفس الوقت");
      return;
    }

    // Determine the next ID based on the current max ID in the invitationsArray
    const maxId = invitationsArray.length > 0 ? Math.max(...invitationsArray.map(invitation => invitation.id)) : 0;
    const newId = maxId + 1;

    setInvitationsArray((prevArray) => {
      const updatedArray = [...prevArray, { id: newId, text: `الدعوة ${newId}` }];
      localStorage.setItem('invitationsArray', JSON.stringify(updatedArray)); // Update localStorage immediately
      return updatedArray;
    });

    setIterator((prevIterator) => {
      const newIterator = prevIterator + 1;
      localStorage.setItem('iterator', newIterator.toString()); // Update localStorage immediately
      return newIterator;
    });
  };

  const removeInvitation = () => {
    setInvitationsArray((prevArray) => {
      const updatedArray = prevArray.filter((invitation) => invitation.id !== selectedInvitation.id);
      localStorage.setItem('invitationsArray', JSON.stringify(updatedArray)); // Update localStorage immediately
      return updatedArray;
    });
    setIterator((prevIterator) => {
      const newIterator = prevIterator - 1;
      localStorage.setItem('iterator', newIterator.toString()); // Update localStorage immediately
      return newIterator;
    });
  };

  const upBarContent = (
    <Button
      b_color={colors.dark_grey}
      className={iterator >= 5 ? "start_new_invitation disabled" : "start_new_invitation"}
      onClick={createInvitation}
    >
      <span>+</span>إنشاء دعوة
    </Button>
  );

  return (
    <>
      <HomeLayout
        page_title="إرسال دعوة"
        upBarContent={upBarContent}
        mainContent={
          <>
            <ButtomLayout
              tableClassName="buttom_full"
              children={
                <>
                  <div className="slider">
                    <InvitationsSlider invitations_array={invitationsArray} />
                  </div>
                  <div className="create-steps">
                    {selectedInvitation && <CreateInviteSteps onSentSuccess={removeInvitation} />}
                  </div>
                </>
              }
            />
          </>
        }
      />
      {showAlert !== null && <CustomAlert message={showAlert} onClose={handleCloseAlert} />}
      {buyPlanshowAlert !== null && (
        <CustomAlert
          message={buyPlanshowAlert}
          children={
            <div>
              <Button
                onClick={handleBuyPlanAlert}
                className="close-alert-btn"
                b_color={colors.light_gold}
                t_color={colors.white}
              >
                شراء باقة
              </Button>
            </div>
          }
          onClose={handleCloseBuyPlanAlert}
        />
      )}
    </>
  );
}

export default InvitationsPage;
